import React, { useContext } from "react";
import { Context as AddressContext } from '../context/AddressContext';
import AddressForm from '../components/AddressForm';
import { useNavigate, useLocation } from "react-router-dom";

const AddressModifyScreen = () => {
    const {state, updateAddress} = useContext(AddressContext);
    const navigate = useNavigate();
    const location = useLocation();
    const item = location.state.item;

    const nav = () => {
        navigate(-1);
    };

    return(
        <AddressForm
            item={item}
            onSubmit={updateAddress}
            nav={nav}
            errorMessage={state.errorMessage}
        />
    );
};

export default AddressModifyScreen;