import React, { useContext, useEffect } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { useNavigate } from "react-router-dom";
import { FiUser, FiLogOut } from "react-icons/fi";
import { MdBusiness } from "react-icons/md";
import { BiMap } from "react-icons/bi";
import { AiOutlineQuestion, AiOutlineRight } from "react-icons/ai";
import { FaHome } from "react-icons/fa";

const AccountScreen = () => {
    const { state, signout, tryLocalSignin } = useContext(AuthContext);
    const { state: { business, ip }, readConfig } = useContext(ConfigContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (state.load === false) {
            tryLocalSignin({ business_id: 5 });
            readConfig(5);
        }
    }, []);

    let options = ['Perfil', 'Sucursales', 'Direcciones', 'Config', 'Social Media', 'Licencia', 'Notifi', 'Tutorials', 'FAQ', 'Cerrar'];
    if (state.id === null) {
        options = options.filter(val => val !== 'Direcciones' && val !== 'Social Media' && val !== 'Cerrar');
    }
    if (state.id === null || state.rol === 'User') {
        options = options.filter(val => val !== 'Config' && val !== 'Social Media' && val !== 'Notifi' && val !== 'Licencia');
    }
    if (state.rol === 'Support') {
        options = options.filter(val => val !== 'Licencia');
    }
    let flag = false;
    /*
    if(new Date(business[0].license) <= new Date()){
        flag = true;
    }
    */

    return (
        <div className="App">
            <div className="navContainer">
                <div className="navBar">
                    {business.length > 0 && <div className="headerNameCenter">{business[0].name}</div>}
                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon" />
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title">Cuenta</div>
                </div>
            </div>
            <div className="configList">
                <div className="configCard">
                    {state.rol === 'Super' && <div>ip: {ip}</div>}
                    {options.map((item, key) => {
                        switch (item) {
                            case 'Perfil':
                                return (
                                    <div key={key} className="configBox" onClick={() => state.id != null
                                        ? navigate("/account_modify")
                                        : navigate("/signin")}>
                                        <FiUser className="config_icon" color="#0094C2" />
                                        {state.id != null
                                            ? <div className="config_text">{state.name}</div>
                                            : <div className="config_text">{item}</div>
                                        }
                                    </div>
                                );
                            case 'Sucursales':
                                return (
                                    <div key={key} className="configBox" onClick={() => navigate("/offices")}>
                                        <MdBusiness className="config_icon" color="brown" />
                                        <div className="config_text">{item}</div>
                                    </div>
                                );
                            case 'Direcciones':
                                return (
                                    <div key={key} className="configBox" onClick={() => navigate("/address")}>
                                        <BiMap className="config_icon" color="green" />
                                        <div className="config_text">{item}</div>
                                    </div>
                                );
                            case 'FAQ':
                                return (
                                    <div key={key} className="configBox" onClick={() => navigate("/faqs")}>
                                        <AiOutlineQuestion className="config_icon" color="gray" />
                                        <div className="config_text">{item}</div>
                                    </div>
                                );
                            case 'Cerrar':
                                return (
                                    <div key={key} className="configBox" onClick={() => {
                                        let nav = navigate("/");
                                        signout({ id: state.id }, nav);
                                    }}>
                                        <FiLogOut className="config_icon" color="red" />
                                        <div className="config_text">Cerrar Sesión</div>

                                    </div>
                                );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export default AccountScreen;