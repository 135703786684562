import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import sha256 from 'crypto-js/sha256';
import ExportPDF from '../components/ExportPDF';

const AuthLoadScreen = () => {
    const navigate = useNavigate();
    const { state: { UserData } } = useContext(AuthContext);
    const { state: { GoogleItems, printOrder, printId } } = useContext(OrderContext);
    const { state: { business } } = useContext(ConfigContext);


    async function hashString(str) {
        const encoder = new TextEncoder();
        const data = encoder.encode(str);
        const hashBuffer = await crypto.subtle.digest("SHA-256", data);
        const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
        return hashHex;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => navigate("/products", { replace: true }), 2500);

        if (GoogleItems !== null) {
            let Purchase = {
                transaction_id: GoogleItems.id,
                value: GoogleItems.amount,
                tax: 0,
                shipping: GoogleItems.service,
                currency: "MXN",
                coupon: "",
                user_data: {
                    email_address: sha256(UserData.email.toLowerCase()).toString(),
                    phone_number: sha256(UserData.phone).toString(),
                    address: {
                        first_name: sha256(UserData.name.toLowerCase()).toString(),
                        last_name: sha256(UserData.lastname.toLowerCase()).toString(),
                        city: sha256(GoogleItems.address.city.toLowerCase()).toString(),
                        region: sha256(GoogleItems.address.state1.toLowerCase()).toString(),
                        postal_code: sha256(GoogleItems.address.cp).toString(),
                        country: sha256('mx').toString(),
                    },
                },
            };

            let ItemsPur = [];
            let arrayItems = [];
            GoogleItems.items.forEach(value => {
                ItemsPur.push({
                    item_id: value.product_id,
                    item_name: value.productname,
                    affiliation: GoogleItems.business_name,
                    coupon: "",
                    discount: value.discount,
                    index: 0,
                    item_brand: GoogleItems.business_name,
                    item_category: "",
                    item_variant: value.colorname,
                    location_id: "",
                    price: value.price,
                    quantity: value.items
                });
                arrayItems.push(value.product_id);
            });

            Purchase.items = ItemsPur;
            window.gtag("event", "purchase",
                Purchase
            );

            window.fbq('track', 'Purchase', {
                content_ids: arrayItems,
                value: parseFloat(GoogleItems.amount),
                currency: 'MXN',
                content_type: 'product',
            });
        }

    }, []);

    return (
        <div className="App">
            <div className="no-print">
                <div className="confirmation_card_confirm_up">
                    <div className="confirmation_confirm_box">
                        <BsFillCheckCircleFill size={54} color='#fff' />
                        <div className="confirmation_confirm_text">Gracias por tu compra!</div>
                    </div>
                </div>
                <div className="confirmation_card_confirm_down">

                </div>
            </div>
            {printOrder &&
                <ExportPDF id={printId} auto={true} />
            }
        </div>
    )

};

export default AuthLoadScreen;