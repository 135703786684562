import createDataContext from './createDataContext';
import Api from '../api/Api';
//import { navigate } from '../navigationRef';
import axios from "axios";

const configReducer = (state, action) => {
    switch (action.type) {
        case 'read_config':
            return action.payload;
        case 'set_baseURL':
            return { ...state, baseURL: action.payload + '/assets/main/' };
        case 'update_config':
            return action.payload;
        case 'read_business':
            return { ...state, business: action.payload };
        case 'update_socialMedia':
            let updateSocial = state.business;
            updateSocial[0].phone = action.payload.whatsapp;
            updateSocial[0].facebook = action.payload.facebook;
            updateSocial[0].instagram = action.payload.instagram;
            return { ...state, business: updateSocial };
        case 'set_ip':
            return { ...state, ip: action.payload };
        case 'error':
            return { ...state, errorMessage: action.payload };
        default:
            return state;
    }
};

const readConfig = (dispatch) => {
    return async (req) => {
        try {
            const response = await Api.get('/readConfig?code=' + req);
            dispatch({ type: 'read_config', payload: response.data });
            dispatch({ type: 'set_baseURL', payload: response.config.baseURL });

            //const res = await axios.get("https://api.ipify.org/?format=json");
            //dispatch({ type: 'set_ip', payload: res.data.ip });
        } catch (err) {
            console.log(err.message);
            dispatch({ type: 'error', payload: '* Error al cargar configuraciones \n(' + err.message + ').' });
        }
    };
};

const updateConfig = (dispatch) => {
    return async (req, data) => {
        try {
            await Api.put('/updateConfig', req, {
                headers: { 'Content-Type': 'multipart/form-data', },
                transformRequest: formData => formData,
            });
            dispatch({ type: 'update_config', payload: data });
            //navigate('Account');
        } catch (err) {
            console.log(err.message);
            dispatch({ type: 'error', payload: '* Error al actualizar configuraciones \n(' + err.message + ').' });
        }
    };
};

const readBusiness = (dispatch) => {
    return async () => {
        try {
            const response = await Api.get('/readBusiness?code=5');
            dispatch({ type: 'read_business', payload: response.data });
        } catch (err) {
            console.log(err.message);
            dispatch({ type: 'error', payload: '* Error al cargar Licencia \n(' + err.message + ').' });
        }
    };
};

const updateBusiness = (dispatch) => {
    return async (nav, req) => {
        try {
            await Api.post('/updateBusiness', req);
            nav.goBack();
            //dispatch({ type:'read_business', payload: response.data });
        } catch (err) {
            //dispatch({type: 'error', payload:'* Error al cargar Licencia \n('+err.message+').'});
        }
    };
};

const sendNotify = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/sendNotify2', req);
            //navigate('Account');                         
        } catch (err) {
            console.log(err.message);
            dispatch({ type: 'error', payload: '* Error al enviar Notificación \n(' + err.message + ').' });
        }
    };
};

const updateSocialMedia = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/updateSocialMedia', req);
            dispatch({ type: 'update_socialMedia', payload: req });
            //navigate('Account');                         
        } catch (err) {
            console.log(err.message);
            dispatch({ type: 'error', payload: '* Error en social media \n(' + err.message + ').' });
        }
    };
};

export const { Provider, Context } = createDataContext(
    configReducer,
    { readConfig, updateConfig, readBusiness, updateBusiness, sendNotify, updateSocialMedia },
    {
        showtag: false, isimage: false, imagefull: false, showcat: false, showbutton: false,
        isservice: false, serviceweight: 0, servicecost: 0,
        ispicking: false, pickid: null,
        iswholesale: false, wholesalecount: 0, wholesalediscount: 0,
        iscredit: false, creditkey: '', credittoken: '',
        isspei: false, speiclave: '', iscash: false,
        errorMessage: '', business: [], baseURL: '', ip: null
    }
);