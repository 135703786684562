import React, { useContext, useEffect } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { Navigation } from '../components/navigation';
import { Cart } from '../components/cart';
import { Filter } from '../components/filter.js';
import ProductBox from '../components/ProductBox';
import { useNavigate} from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const ProductFavoriteScreen = () => {

    const {state:{id}} = useContext(AuthContext);
    const {state, getProductDetail, updateFavorites, readFavoritesCat} = useContext(ProductContext);
    const {state:{showtag}} = useContext(ConfigContext);
    const navigate = useNavigate();
    const Filter = state.Product.filter((value) => {
        return state.Favorites.some((val) => {
          return val.product_id === value.id;
        });
      });
    
    useEffect(() => {
        readFavoritesCat({account_id:id});
        window.scrollTo(0, 0);
    },[]);


    return(
        <div className="App">
            {state.toggleCart && <Cart/>}
            {state.toggleFilter && <Filter />}
            <Navigation/>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Favoritos</div>
                </div>
            </div>
            <div className="productTitle2">Favoritos</div>
            {Filter.length === 0 &&
                <div className="style_full_height"/>
            }
            <div className="productList">
                {Filter.filter((item) => {
                const itemData = item.name.toUpperCase();
                const textData = state.Search.toUpperCase();
                return itemData.indexOf(textData) > -1;}).map((item) => {
                    let favoritos = state.Favorites.find(val => val.product_id === item.id);
                    if(favoritos == undefined){
                        favoritos = {id:null, product_id:item.id, account_id:id}
                    }
                    return(
                    <div key={item.id} >
                        <ProductBox 
                            item={item}
                            baseURL={state.baseURL}
                            getProductDetail={getProductDetail}
                            Favorites={favoritos}
                            updateFavorites={updateFavorites}
                            showtag={showtag}
                        />
                    </div>
                    );
            })}
            
            </div>
            
        </div>
    );
};

export default ProductFavoriteScreen;