import React, { useContext } from 'react';
import { Context as AddressContext } from '../context/AddressContext';
import OfficesForm from '../components/OfficesForm';
import {useLocation} from 'react-router-dom';

const OfficesModifyScreen = () => {
    const location = useLocation();
    const item = location.state.item;
    const {state, updateOffices} = useContext(AddressContext);
    return (
        <div>
            <OfficesForm
                item={item}
                onSubmit={updateOffices}
                errorMessage={state.errorMessage}
            />
        </div>
    );
};

export default OfficesModifyScreen;