import React, { useContext } from 'react';
import { Context as ConfigContext } from '../context/ConfigContext';
import { useNavigate } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const FAQScreen = () => {
    const {state:{business}} = useContext(ConfigContext);
    const navigate = useNavigate();
    
    return (
        <div className='App'>
            <div className="navContainer">
                <div className="navBar">
                    {business.length > 0 && <div className="headerNameCenter">{business[0].name}</div>}
                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/account', { replace: true })}>Cuenta</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Preguntas Frecuentes</div>
                </div>
            </div>
            <div className='offices_Title'>Preguntas Frecuentes</div>

            <div className='FAQ_list'>
                <div className="FAQ_Card">
                    <div className='FAQ_title'>ENVIOS</div>

                    <div className='FAQ_question'>-¿Cuánto tiempo tarda en llegar mi pedido?</div>
                    <div className='FAQ_answer'>Los pedidos tardan entre 4 y 7 días hábiles.</div>

                    <div className='FAQ_question'>- ¿Cuáles son las tarifas de envío?</div>
                    <div className='FAQ_answer'>La tarifa de envío se va actualizando conforme se van agregando productos al carrito de compras.</div>

                    <div className='FAQ_question'>- ¿En cuánto tiempo tendré mi guía para rastrear mi pedido?</div>
                    <div className='FAQ_answer'>Una vez realizada la compra se te enviará un notificación de confirmación, posteriormente una vez listo tu pedido recibirás en otro notificación el número de rastreo en un lapso máximo de 24hrs.</div>

                    <div className='FAQ_question'>- ¿Hacen envíos a toda la República Mexicana?</div>
                    <div className='FAQ_answer'>Claro, hacemos envíos a toda la República Mexicana</div>
                </div>

                <div className="FAQ_Card">
                    <div className='FAQ_title'>PEDIDOS</div>

                    <div className='FAQ_question'>- ¿Cómo sabré que mi pedido ha sido realizado exitosamente?</div>
                    <div className='FAQ_answer'>Recibirás un notificación de confirmación.</div>

                    <div className='FAQ_question'>-¿Cuánto tiempo tarda en enviarse mi pedido?</div>
                    <div className='FAQ_answer'>Para pedidos de menudeo se envían en un lapso de 1 a 2 días habilies y para pedidos por mayoreo en un lapso de 4 a 7 días hábiles. El servicio de recolección de la paquetería es de lunes a viernes en días no feriados.</div>
                </div>

                <div className="FAQ_Card">
                    <div className='FAQ_title'>DEVOLUCIONES Y CAMBIOS</div>

                    <div className='FAQ_question'>-Quiero cambiar de talla ¿Qué hago?</div>
                    <div className='FAQ_answer'>Para realizar un cambio de talla se deberá solicitar el cambio en los primeros 3 días a partir de que se entregó el paquete, en caso de no tener existencia de la talla solicitada el cambio puede ser por modelo siempre y cuando sea del mismo precio o mayor pagando la diferencia, no realizamos reembolso. El cambio va por cuenta del cliente.</div>
                    <div className='FAQ_answer'>El producto debe venir sin HABER SIDO USADO, en buen estado, accesorios completos y en su empaque original</div>
                    <div className='FAQ_answer'>No se aceptan devoluciones ocasionadas por mal uso del producto.</div>

                    <div className='FAQ_question'>- ¿Puedo regresar o cambiar directamente en sucursal física el producto?</div>
                    <div className='FAQ_answer'>Si puedes realizar cambios o devoluciones en las tiendas físicas más cercanas a su domicilio</div>
                    <div className='FAQ_answer'>En compras a meses sin intereses no aplican devoluciones, solo cambios.</div>
                    <div className='FAQ_answer'>No hay cambios ni devoluciones en compras con descuento</div>

                </div>
            </div>
        </div>
    );
};


export default FAQScreen;