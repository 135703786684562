import React, { useContext, useEffect } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext.js';
import { Context as ConfigContext } from '../context/ConfigContext';
import { Navigation } from '../components/navigation';
import { Cart } from '../components/cart';
import { Filter } from '../components/filter.js';
import ProductBox from '../components/ProductBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";


const ProductCategorieScreen = () => {
    const location = useLocation();
    const subCategorie = location.state.item;
    const navigate = useNavigate();
    const { state: { id, load }, tryLocalSignin } = useContext(AuthContext);
    const { state, readProductCat, getProductDetail, updateFavorites, readProduct } = useContext(ProductContext);
    const { getBasketV2 } = useContext(OrderContext);
    const { state: { showtag }, readConfig } = useContext(ConfigContext);
    let FilterArray;
    let title = '';
    switch (subCategorie) {
        case 'new':
            title = 'NUEVOS PRODUCTOS';
            FilterArray = state.Product.filter(value => value.new === true);

            break;
        case 'renew':
            title = 'RELANZAMIENTOS';
            FilterArray = state.Product.filter(value => value.renew === true);
            break;
        default:
            title = subCategorie.name;
            FilterArray = state.Product.filter(value => value.subcategories_id === subCategorie.id);
            break;
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        readProductCat(subCategorie);
        if (load === false) {
            tryLocalSignin({ business_id: 5 });
            readConfig(5);
            getBasketV2();
            if (subCategorie.name === undefined) {
                readProduct({ code: 5, account_id: id });
            }
        }
    }, []);

    return (
        <div className="App">
            {state.toggleCart && <Cart />}
            {state.toggleFilter && <Filter />}
            <Navigation />
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon" />
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title">{title}</div>
                </div>
            </div>
            <div className="productTitle2">{title}</div>
            <div className="productList">
                {FilterArray.filter((item) => {
                    const itemData = item.name.toUpperCase();
                    const textData = state.Search.toUpperCase();
                    return itemData.indexOf(textData) > -1;
                }).map((item) => {
                    let favoritos = state.Favorites.find(val => val.product_id == item.id);
                    if (favoritos === undefined) {
                        favoritos = { id: null, product_id: item.id, account_id: id }
                    }
                    return (
                        <div key={item.id} >
                            <ProductBox
                                item={item}
                                baseURL={state.baseURL}
                                getProductDetail={getProductDetail}
                                showtag={showtag}
                                Favorites={favoritos}
                                updateFavorites={updateFavorites}
                                type={subCategorie}
                            />
                        </div>
                    );
                })}
            </div>

        </div>
    );
};

export default ProductCategorieScreen;