import React, { useContext } from "react";
import { Context as ConfigContext } from '../context/ConfigContext';
import { useNavigate } from "react-router-dom";
import { BsWhatsapp, BsFacebook, BsInstagram } from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
import logo from '../assets/logo2.png';
import falconSoft from '../assets/falconSoft.png';
import playstore from '../assets/playstore.png';
import appstore from '../assets/appstore.png';

const Footer = () => {
    const {state} = useContext(ConfigContext);
    const navigate = useNavigate();
    
    
    
    const bs = state.business[0];


    return(
        <div className="no-print">
            <div className="footList">

                <div className="footCard">  
                    <img src= {logo} alt="logo" className="footLogo" style={{marginTop:20}} />  
                    <div className="foot_row_images" >
                        <img src= {playstore} alt="logo" className="foot_ImageStore" onClick={() => 
                                window.open('https://play.google.com/store/apps/details?id='+bs.play_store)}/> 
                        <img src= {appstore} alt="logo" className="foot_ImageStore" onClick={() => 
                                window.open('https://apps.apple.com/mx/app/'+bs.app_store)}/>         
                    </div>
                </div>

                <div className="footCard">
                    <div className="footView">
                        <div className="footTitle">SERVICIOS</div>
                        <div className="footRow" onClick={() => navigate("/product_favorite")}>
                            <AiOutlineRight size={13} color='#fff' style={{paddingRight:5}}/>
                            <div className="fooText">Favoritos</div>
                        </div>
                        <div className="footRow" onClick={() => navigate("/orders")}>
                            <AiOutlineRight size={13} color='#fff' style={{paddingRight:5}}/>
                            <div className="fooText">Mis pedidos</div>
                        </div>
                        <div className="footRow" onClick={() => navigate("/account")}>
                            <AiOutlineRight size={13} color='#fff' style={{paddingRight:5}}/>
                            <div className="fooText">Cuenta</div>
                        </div>
                        <div className="footRow" onClick={() => navigate("/")}>
                            <AiOutlineRight size={13} color='#fff' style={{paddingRight:5}}/>
                            <div className="fooText">Sucursales</div>
                        </div>
                    </div>
                </div>

                <div className="footCard">
                    {bs !== undefined &&
                    <div className="footView">
                    <div className="footTitle">REDES SOCIALES</div>
                        {(bs.phone !== null && bs.phone !== '') &&
                        <div className="footRow" onClick={() => 
                                window.open(`https://api.whatsapp.com/send?phone=+52${bs.phone}&text=Tengo una duda `)}>
                            <BsWhatsapp className="footIcon" color='#25d366'/>
                            <div className="fooText">{bs.phone}</div>
                        </div>
                        }
                        <div className="footRow" onClick={() => 
                                window.open(`https://api.whatsapp.com/send?phone=+524776740981&text=Tengo una duda `)}>
                            <BsWhatsapp className="footIcon" color='#25d366'/>
                            <div className="fooText">4776740981</div>
                        </div>
                        {(bs.facebook !== null && bs.facebook !== '') &&
                        <div className="footRow" onClick={() => 
                                window.open('https://www.facebook.com/'+bs.facebook)}>
                            <BsFacebook className="footIcon" color='#4267B2' />
                            <div className="fooText">Facebook</div>
                        </div>
                        }
                        {(bs.instagram !== null && bs.instagram !== '') &&
                        <div className="footRow" onClick={() => 
                                window.open('https://www.instagram.com/'+bs.instagram)}>
                            <BsInstagram className="footIcon" color='#833AB4'/>
                            <div className="fooText">Instagram</div>
                        </div>
                        }
                    </div>
                    }
                </div>

                <div className="footCard">  
                    <div className="footView">
                        <div className="footTitle">POWERED BY</div>
                    </div>
                    <img src= {falconSoft} alt="FalconSoft" className="footLogo" />  
                    <div className="footView">
                        <div className="fooText footStyle">Desarrollo de aplicaciones</div>
                        <div className="footRow" onClick={() => 
                                window.open(`https://api.whatsapp.com/send?phone=+524776764948&text=Me interesa el sistema E-commerce `)}>
                            <BsWhatsapp className="footIcon" size={20} color='#25d366'/>
                            <div className="fooText footStyle">477 676 49 48</div>
                        </div>
                        <div className="footRow" onClick={() => 
                                window.open('https://www.facebook.com/100064069481909')}>
                            <BsFacebook className="footIcon" size={20} color='#4267B2'/>
                            <div className="fooText footStyle">FalconSoft</div>
                        </div>

                    </div> 
                </div>

            </div>
            <div className="footRights">Copyright © {bs !== undefined ? state.business[0].name : null} - V1.0.1 </div>
            <div className="footPrivacy" onClick={() => navigate("/privacy")}>Política de privacidad</div>
        </div>
    )
};

export default Footer;
