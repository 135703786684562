import createDataContext from './createDataContext';
import Api from '../api/Api';
//import { navigate } from '../navigationRef';

const productsReducer = (state, action) =>{
    switch(action.type){
        case 'read_product':
            return {...state, 
                Product: action.payload.data.product, 
                Categories: action.payload.data.categories,
                subCategories: action.payload.data.subCategores,
                baseURL: action.payload.url+'/assets/products/',
                opened: action.payload.opened,
                errorMessage:'',
                importF:false,
                load:false,
                page: action.payload.data.page
             };
        case 'read_product_more':
        let addProductsMore = state.Product;
            action.payload.product.forEach(value => {
                if(!addProductsMore.find(val => val.id === value.id)){
                    addProductsMore.push(value);
                }
            });
            return {...state, Product:addProductsMore, page:action.payload.page, load:false };
        case 'read_product_orders':
        let addProducts = state.Product;
        action.payload.forEach(value => {
            if(!addProducts.find(val => val.id === value.id)){
                addProducts.push(value);
            }else{
                addProducts.map(val => val.id === value.id
                    ? val.colors = value.colors
                    : val);
            }
        });
            return {...state, Product:addProducts, load:false };
        case 'get_product_detail':
            let findDetail = state.Product.find(val => val.id === action.payload.id);
            let updateDetail = state.Product;

            if(findDetail){
                updateDetail = updateDetail.map(val => val.id === action.payload.id
                    ? {...val, ...action.payload, reload:!val.reload, detail:true}
                    : val );
            }else{
                
                updateDetail.push({...action.payload, reload:true, detail:true })
            }
            return {...state, Product:updateDetail, load:false, errorMessage:'' };
        case 'read_product_scan':
            const updateScan = state.Product;
            if(!updateScan.find(value => value.id === action.payload.id)){
                updateScan.push(action.payload);
            }else{
                updateScan.map(val => val.id === action.payload.id
                    ? val.colors = action.payload.colors
                    : val);
            }
            return {...state, Product:updateScan, load:false };
        case 'read_product_cat':
            let addCat = state.Product;
            action.payload.forEach(value => {
                if(!addCat.find(val => val.id === value.id)){
                    addCat.push(value);
                }
            });
            return {...state, Product:addCat, load:false };
        case 'readFavoritesCat':
            let addFav = state.Product;
            action.payload.forEach(value => {
                if(!addFav.find(val => val.id === value.id)){
                    addFav.push(value);
                }
            });
            return {...state, Product:addFav, load:false };
        case 'read_product_basket':
            let addProductsBasket = state.Product;
            action.payload.forEach(value => {
                if(!addProductsBasket.find(val => val.id === value.id)){
                    addProductsBasket.push(value);
                }else{
                    addProductsBasket.map(val => val.id === value.id
                        ? val.colors = value.colors
                        : val);
                }
            });
                return {...state, Product:addProductsBasket, load:false };
        case 'search_product':
            let addSearch = state.Product;
            action.payload.forEach(value => {
                if(!addSearch.find(val => val.id === value.id)){
                    addSearch.push(value);
                }
                /*else{
                    addSearch.map(val => val.id === value.id
                        ? val.colors = value.colors
                        : val);
                }*/
            });
            return {...state, Product:addSearch, load:false };
        case 'add_product':
            let add = state.Product;
            add.unshift({...action.payload, new:true});
            return {...state, Product:add };
        case 'update_product':
            const updated = state.Product.map(val => val.id === action.payload.id
                ? action.payload
                : val );
            return {...state, Product:updated };
        case 'update_product_qty':
            state.Product.map(value => value.id === action.payload.product_id
                    ? value.colors.map(val => val.id === action.payload.productcolor_id
                        ? val.sizes.map(v => v.id === action.payload.id
                            ? v.quantity += action.payload.input
                            : v)
                        : val)
                    : value);
            return {...state, errorMessage:'' };
        case 'delete_product':
            const product = state.Product.filter((value) => value.id !== action.payload.id);
            return {...state, Product:product };
        case 'add_categorie':
                let add2 = state.Categories;
                add2.push(action.payload);
            return {...state, Categories:add2 };
        case 'update_categorie':
            const updateCat = state.Categories.map(val => val.id === action.payload.id
                ? action.payload
                : val);
            return {...state, Categories:updateCat };
        case 'delete_categorie':
            const deleteCat = state.Categories.filter(val => val.id !== action.payload.id);
            return {...state, Categories:deleteCat };               
        case 'add_sub_cat':
                let add3 = state.subCategories;
                add3.push(action.payload);
            return {...state, subCategores:add3 };
        case 'update_sub_cat':
            const updateSubCat = state.subCategories.map(val => val.id === action.payload.id
                ? action.payload
                : val);
            return {...state, subCategories:updateSubCat };
        case 'delete_sub_categorie':
            const deleteSubCat = state.subCategories.filter(val => val.id !== action.payload.id);
            return {...state, subCategories:deleteSubCat };
        case 'import_flag':
            return {...state, importF: action.payload };
        case 'get_records':
            return {...state, Records: action.payload, load:false };
        case 'remove_records':
            return {...state, Records: [] };   
        case 'load':
            return {...state, load: true };    
        case 'update_transf':
            action.payload.data.forEach(item => {
                state.Product.map(value => value.id === item.product_id
                    ? value.colors.map(val => val.id === item.productcolor_id
                        ? val.sizes.map(v => v.id === item.id
                            ? v.quantity -= item.items
                            : v)
                        : val)
                    : value);
            });

            action.payload.data.forEach(item => {
                state.Product.map(value => value.id === item.product_id
                    ? value.colors.map(val => val.id === item.productcolor_id
                        ? val.sizes.map(v => v.productcolor_id === item.productcolor_id &&
                            v.size === item.size && v.offices_id === action.payload.office_to
                            ? v.quantity += item.items
                            : v)
                        : val)
                    : value);
            });
            
            return {...state, errorMessage:''};
        case 'read_inventory_cost':
            return {...state, InventoryCost: action.payload };  
        case 'readFavorites':
            return {...state, Favorites: action.payload };  
        case 'updateFavorites':
            let updFavorites = state.Favorites;
            if(action.payload.id != null){
                updFavorites.push(action.payload);
                
            }else{
                updFavorites = updFavorites.filter(val => val.product_id !== action.payload.product_id);

            }
            return {...state, Favorites: updFavorites };
        case 'setSearch':
            return {...state, Search: action.payload };
        case 'setToggleCart':
            return {...state, toggleCart: !state.toggleCart };
        case 'setToggleFilter':
            return {...state, toggleFilter: !state.toggleFilter };    
        case 'setUrl':
            return {...state,  baseURL: action.payload+'/assets/products/' };   
        case 'error':
            return {...state, errorMessage: action.payload };
        default:
            return state;

    }
};

const readProduct = (dispatch) => {
    return  async (req) =>{ 
        try{              
            //const response = await Api.get('/readProduct?code=5&auto=true');  
            const response = await Api.post('/readProductV1', req);      
            dispatch({ type:'read_product', payload: {data:response.data, url:response.config.baseURL, 
                opened:response.request.OPENED } });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos \n('+err.message+').'});
        }
    };
};

const readProductMore = (dispatch) => {
    return  async (req) =>{ 
        try{            
            dispatch({ type:'load'});      
            const response = await Api.post('/readProductMore', req);      
            dispatch({ type:'read_product_more', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos \n('+err.message+').'});
        }
    };
};

const readProductOrders = (dispatch) => {
    return  async (req) =>{ 
        try{       
            dispatch({ type:'load'});     
            const response = await Api.get('/readProductOrders?id='+req.id);       
            dispatch({ type:'read_product_orders', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos orders \n('+err.message+').'});
        }
    };
};


const readProductAll =  (dispatch) => {
    return  async (fun, req) =>{ 
        try{       
            const response = await Api.post('/readProductAll',req);
            dispatch({ type:'read_product_orders', payload: response.data });
            fun();
        }catch (err){
            dispatch({type: 'error', payload:'* Error cargar productos all \n('+err.message+').'});
        }
    };
};

const getProductDetail = (dispatch) => {
    return  async (req) =>{ 
        try{            
            dispatch({ type:'load'});
            const response = await Api.get('/readProductDetail?id='+req.id);      
            dispatch({ type:'setUrl', payload: response.config.baseURL });    
            dispatch({ type:'get_product_detail', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos detalle \n('+err.message+').'});
        }
    };
};



const readProductScan = (dispatch) => {
    return  async (fun,req) =>{ 
        try{            
            //dispatch({ type:'load'});
            const response = await Api.get('/readProductScan?id='+req.id);   
            if(response.data.length > 0){
                dispatch({ type:'read_product_scan', payload: response.data[0] });   
                fun(req.id, response.data[0]);
            }else{
                fun(req.id, null);
            }
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos scan \n('+err.message+').'});
        }
    };
};

const readProductCat = (dispatch) => {
    return  async (req) =>{ 
        try{            
            dispatch({ type:'load'});
            const response = await Api.get('/readProductCat?id='+req.id+'&all='+req.all);   
            dispatch({ type:'setUrl', payload: response.config.baseURL });     
            dispatch({ type:'read_product_cat', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos categorías \n('+err.message+').'});
        }
    };
};

const readProductCat2 = (dispatch) => {
    return  async (req) =>{ 
        try{            
            dispatch({ type:'load'});
            const response = await Api.post('/readProductCat2', req);      
            dispatch({ type:'read_product_cat', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos categorías 2 \n('+err.message+').'});
        }
    };
};

const readFavoritesCat =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            dispatch({ type:'load'});
            const response = await Api.post('/readFavoritesCat',req);
            dispatch({ type:'readFavoritesCat', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al leer favoritos Cat \n('+err.message+').'});
        }
    };
};
/*
const readProductBasket = (dispatch) => {
    return  async (req, getBasket) =>{ 
        try{            
            dispatch({ type:'load'});
            const response = await Api.get('/readProductBasket?account_id='+req.account_id);      
            dispatch({ type:'read_product_basket', payload: response.data });
            getBasket({account_id:req.account_id, auto:true});
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos basket \n('+err.message+').'});
        }
    };
};
*/
const readProductBasket = (dispatch) => {
    return  async (req, getBasket) =>{ 
        try{            
            dispatch({ type:'load'});
            const response = await Api.post('/readProductBasketV1',req);      
            dispatch({ type:'read_product_basket', payload: response.data });
            getBasket({account_id:req.account_id, auto:true, expoToken:req.expoToken});
        }catch (err){
            dispatch({type: 'error', payload:'* Error al cargar productos basket \n('+err.message+').'});
        }
    };
};

const searchProduct = (dispatch) => {
    return  async (req) =>{ 
        try{       
            dispatch({ type:'load'});   
            const response = await Api.post('/searchProduct', req);      
            dispatch({ type:'search_product', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al buscar productos \n('+err.message+').'});
        }
    };
};

const addProduct =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            const response = await Api.post('/addProduct', req, {
                headers: {'Content-Type': 'multipart/form-data',},
                  transformRequest: formData => formData,
            });

            dispatch({ type:'add_product', payload: response.data });           
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al guardar producto \n('+err.message+').'});
        }
    };
};

const updateProduct =  (dispatch) => {
    return  async (req, data) =>{ 
        try{          
            await Api.put('/updateProduct', req, {
                headers: {'Content-Type': 'multipart/form-data',},
                  transformRequest: formData => formData,
            });
            dispatch({ type:'update_product', payload: data });         
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al actualizar producto \n('+err.message+').'});
        }
    };
};

const updateProductQty =  (dispatch) => {
    return  async (nav,req) =>{ 
        try{          
            await Api.put('/updateProductQty', req);
            dispatch({ type:'update_product_qty', payload: req });  
            //nav.goBack();       
        }catch (err){
            dispatch({type: 'error', payload:'* Error al actualizar producto \n('+err.message+').'});
        }
    };
};

const deleteProduct =  (dispatch) => {
    return  async (req) =>{ 
        try{          
            await Api.post('/deleteProduct', req);
            dispatch({ type:'delete_product', payload: req });            
        }catch (err){
            dispatch({type: 'error', payload:'* Error al eliminar producto \n('+err.message+').'});
        }
    };
};

const addCategorie =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            const response = await Api.post('/addCategorie2', req, {
                headers: {'Content-Type': 'multipart/form-data',},
                  transformRequest: formData => formData,
            });
            dispatch({ type:'add_categorie', payload: response.data });           
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al guardar categoría \n('+err.message+').'});
        }
    };
};

const updateCategorie =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            const response = await Api.put('/updateCategorie2', req, {
                headers: {'Content-Type': 'multipart/form-data',},
                  transformRequest: formData => formData,
            });
            dispatch({ type:'update_categorie', payload: response.data });           
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al actualizar categoría \n('+err.message+').'});
        }
    };
};

const deleteCategorie =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            await Api.post('/deleteCategorie', req);
            dispatch({ type:'delete_categorie', payload: req });           
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al eliminar categoría \n('+err.message+').'});
        }
    };
};

const addSubCategorie =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            const response = await Api.post('/addSubCategorie', req);
            dispatch({ type:'add_sub_cat', payload: response.data });           
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al guardar sub categoría \n('+err.message+').'});
        }
    };
};

const updateSubCategorie =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            await Api.put('/updateSubCategorie', req);
            dispatch({ type:'update_sub_cat', payload: req });           
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al actualizar sub categoría \n('+err.message+').'});
        }
    };
};

const deleteSubCategorie =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            await Api.post('/deleteSubCategorie', req);
            dispatch({ type:'delete_sub_categorie', payload: req });           
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al eliminar categoría \n('+err.message+').'});
        }
    };
};

const importProduct =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            await Api.post('/importProduct', req);
            dispatch({ type:'import_flag', payload: true });
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error importar Productos \n('+err.message+').'});
        }
    };
};

const getRecords =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            dispatch({ type:'load' });
            const response = await Api.post('/getRecords2',req);
            dispatch({ type:'get_records', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error cargar records \n('+err.message+').'});
        }
    };
};

const removeRecords =  (dispatch) => {
    return  (req) =>{ 
        dispatch({ type:'remove_records'});
    };
};



const deleteBasketAuto =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            await Api.post('/deleteBasketAuto',req);
        }catch (err){
            //dispatch({type: 'error', payload:'* Error cargar records \n('+err.message+').'});
        }
    };
};

const updateTransf =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            await Api.post('/updateTransf',req);
            dispatch({ type:'update_transf', payload: req });
            //navigate('Inventory'); 
        }catch (err){
            dispatch({type: 'error', payload:'* Error al transferir \n('+err.message+').'});
        }
    };
};

const readInventoryCost =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            const response = await Api.post('/readInventoryCostV1',req);
            dispatch({ type:'read_inventory_cost', payload: response.data });
        }catch (err){

        }
    };
};

const readFavorites =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            const response = await Api.post('/readFavorites',req);
            dispatch({ type:'readFavorites', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al leer favoritos \n('+err.message+').'});
        }
    };
};



const updateFavorites =  (dispatch) => {
    return  async (req) =>{ 
        try{       
            const response = await Api.post('/updateFavorites',req);
            req.id = response.data.id;
            dispatch({ type:'updateFavorites', payload: req });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al actualizar favoritos \n('+err.message+').'});
        }
    };
};

const setSearch =  (dispatch) => {
    return  (req) =>{ 
        dispatch({ type:'setSearch', payload:req});
    };
};

const setToggleCart =  (dispatch) => {
    return  (req) =>{ 
        dispatch({ type:'setToggleCart'});
    };
};

const setToggleFilter =  (dispatch) => {
    return  (req) =>{ 
        dispatch({ type:'setToggleFilter'});
    };
};

export const {Provider , Context} = createDataContext(
    productsReducer,
    {readProduct, readProductMore, readProductAll, readProductOrders, getProductDetail,
    readProductScan, readProductCat, readProductCat2, readProductBasket, searchProduct, addProduct, updateProduct, updateProductQty, deleteProduct, 
    importProduct,getRecords, addCategorie, updateCategorie, deleteCategorie, addSubCategorie, updateSubCategorie,
    deleteSubCategorie, deleteBasketAuto, removeRecords, updateTransf, readInventoryCost,
    readFavorites, updateFavorites, readFavoritesCat, setSearch, setToggleCart, setToggleFilter},
    { Product: [], Categories: [], subCategories: [], Records:[], Share:[], InventoryCost:[],
        errorMessage: '', baseURL:'', opened:0, importF:false, load:false, page:0, Favorites:[],
        Search: '', toggleCart: false, toggleFilter: false
    }
);