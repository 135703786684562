import React, { useContext, useEffect, useState } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as AddressContext } from '../context/AddressContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import ProductBoxH from '../components/ProductBoxH';
import TotalBox from '../components/TotalBox';
import { Navigation } from '../components/navigation';
import { Cart } from '../components/cart';
import { Filter } from '../components/filter.js';
import { useNavigate} from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const BasketBigScreen = () => {

    const {state, addItem5, deleteItem, setServices, setAccount, getBasket,setSellOnline,
        deleteCorrida, deleteAllItems,getRewardsCount,setIncreaseMoney,setDiscountMoney} = useContext(OrderContext);
    const {state:{Product, baseURL, load, toggleCart, toggleFilter}, getProductDetail, readProductBasket, setToggleCart} = useContext(ProductContext);
    const {state:{id, rol, name}} = useContext(AuthContext);
    const {state:{business,iswholesale,wholesale, isservice,service,discount_corrida,
        sellonline,expire_days, rewards_days}} = useContext(ConfigContext);
      
    const [Mayoreo, setMayoreo] = useState(true);
    const [discount1, setDiscount1] = useState(false);
    const [discount2, setDiscount2] = useState(false);

    
    const [input, setInput] = useState(0);
    const handleChange = ({ target: { value, name } }) =>
    setInput(value);

    let render = false;
    useEffect(() => {
    if(render === false){
        let getId; 
        if(state.account_id !== null){
        getId = state.account_id.id;
        }else{
        getId = id;
        }
        getBasket({account_id:getId, auto:true});
        readProductBasket({account_id:getId});
    }
    render = true;
    },[]);

    const navigate = useNavigate();


    let color_id;
    let totalItems = state.SelectedItems.reduce((sum, value) => ((typeof value.items == "number" ) 
    //&& value.show_corrida == null
    && !value.prod_disc
    ? sum + value.items : sum), 0);

    let getDiscount = iswholesale ? wholesale.filter(val => val.wquantity <= totalItems).reverse().find(val => 
        totalItems >= val.wquantity) : undefined;
    if(!Mayoreo){
        getDiscount = undefined;
    }
    let discount = getDiscount ? getDiscount.wdiscount : undefined;

    
    let weight = 0;
    let serv = 0;
    let value = 0;
    let subTotalCorrida = 0;
    let total = 0;
    let revision = false;
    state.SelectedItems.forEach(value => {  
        let product = Product.find(val => val.colors.find(val => val.id === value.productcolor_id));
        if(product !== undefined){
            weight = weight + (product.weight * value.items);
            let productPrice = product.price;
            if(state.increaseMoney !== null){
                productPrice += state.increaseMoney;
            }
            let findPlus = state.Plus.find(val => val.id_basket === value.id_basket);
            if(findPlus){
                value.plus = findPlus.plus;
                productPrice += findPlus.plus;
            }
            if(value.show_corrida === true){
                subTotalCorrida += ((productPrice - discount_corrida) * value.items);
            }else{
                let finalPrice = productPrice;
                    if(product.discount > 0 ){
                        finalPrice = ((finalPrice - product.discount) * value.items);
                        if(state.sellOnline && sellonline > 0){
                            finalPrice = finalPrice + ((finalPrice * sellonline) / 100);
                        }
                    }else if(getDiscount !== undefined){
                        finalPrice = ((finalPrice - getDiscount.wdiscount) * value.items);
                    }else{
                        finalPrice = finalPrice * value.items;
                    }
                
                    total += finalPrice;
            }
            if(product.revision){
            revision = true;
            }
        }
    });

    if(isservice){
        let highService = service[service.length -1].sf;
        let rounds = Math.ceil(weight/highService);
        for(let i = 1; i <= rounds; i++){          
            if(weight > highService){
                value = highService;
                weight = weight - highService;
            }else{
                value = weight;
            }     
            let getService = service.find(val => val.si < value && val.sf >= value);
            //serv = getService != undefined ? serv + getService.sc : 0;  
            if(getService !== undefined){
                serv = serv + getService.sc;
            }else{
                serv = 0;
            }       
        }
    }
    
    total += subTotalCorrida;

    if(state.discountMoney !== 0){
        total = total - state.discountMoney;
    }

    let comments = null;
    if(state.rewards_count === rewards_days && rewards_days > 0){
        serv = 0;
        comments = 'Rewards: Envío gratis';
    }
    //total = total + serv;
    if(state.SelectedItems.length > 0){
        total = parseFloat(total).toFixed(0);
        let msDiff = new Date().getTime() - new Date(state.SelectedItems[0].created_on).getTime();
        let date = msDiff / (1000 * 60 * 60 * 24);
        let countdown = parseInt(parseInt(expire_days) - date);
        let time = Math.floor(msDiff / (1000 * 60 * 60 )) ;
        time = ((parseInt(expire_days) - countdown) * 24) - time;
        let dateMsj = '';
        if(countdown === 0){
            time = time < 0 ? 0 : time;
            dateMsj = time +' horas'
        }else if(countdown === 1){
            dateMsj = countdown +' día '+time +' horas'
        }else{
            dateMsj = countdown +' días '+time +' horas'
        }
    }

    return(
        <div className="App">
            {toggleCart && <Cart/>}
            {toggleFilter && <Filter />}
            <Navigation/>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Carrito de compras</div>
                </div>
            </div>
            <div className="productTitle2">Carrito de compras</div>
            
            <div className="productScanList">
                <div className="productBasketCard1">
                
                {state.SelectedItems.map((item,key) => {
                    item.account_id = state.account_id === null ? id : state.account_id.id;
                    let flag = item.show_corrida && color_id === item.productcolor_id ? false : true;
                    color_id = item.show_corrida ? item.productcolor_id : null;
                    let corrida_array = [];
                    if(item.show_corrida){
                        corrida_array = state.SelectedItems.
                            filter(val => val.productcolor_id === item.productcolor_id && val.show_corrida);
                    }

                    return (
                    <div>
                    {flag &&
                    <ProductBoxH key={key}
                        Product={Product.find(value => value.colors
                        .find(val => val.id === item.productcolor_id))}
                        item={item}
                        addItem={addItem5}
                        addItemHold={state.addItemHold} 
                        URL={baseURL}
                        editable={true}
                        deleteItem={deleteItem}
                        discount2={discount}
                        discount_corrida={discount_corrida} 
                        increaseMoney={state.increaseMoney}
                        corrida_array={corrida_array}
                        deleteCorrida={deleteCorrida}
                        sellonline={state.sellOnline ? sellonline : 0}
                        link={true}
                        getProductDetail={getProductDetail}
                        rol={rol}
                    />
                    }
                    </div>
                    );
                    })}
                
                
                </div>

                <div className="productBasketCard2">
                <>
                <TotalBox
                      Product={Product}
                      item={state.SelectedItems}
                      discount2={discount}
                      discount_corrida={discount_corrida}
                      sellonline={state.sellOnline ? sellonline : 0}
                      service={0}
                      showService={false}
                      discount_money={state.discountMoney}
                      increaseMoney={state.increaseMoney}
                  />

                <div className="primary__button cart"
                onClick={() => {
                  setServices({service:serv, discount, amount:total, revision, comments});
                  setToggleCart();
                  navigate("/confirmation");
                }}
                >Continuar</div>
              </>
                </div>
            </div>
            
        </div>
    );
};

export default BasketBigScreen;