import createDataContext from './createDataContext';
import Api from '../api/Api';
//import { navigate } from '../navigationRef';

const ContactReducer = (state, action) =>{
    switch(action.type){
        case 'read_contacts':
            return {...state, Contacts: action.payload.data, opened: action.payload.opened};
        case 'update_contact':
            const update = state.Contacts.map(val => val.id == action.payload.id
                ? action.payload
                : val);
            return {...state, Contacts: update };
        case 'create_contact':
            const create = state.Contacts;
            create.push(action.payload);
            return {...state, Contacts: create };
        case 'error':
            return {...state, errorMessage: action.payload };
        case 'delete_contact':
            const deleted = state.Contacts.filter(val => val.id != action.payload.id);
            return {Contacts: deleted };
        case 'read_account':
            return {...state, Account: action.payload };
        case 'update_account':
            return {...state, Account: action.payload };
        case 'read_credit_note':
            const updCre = state.Contacts;
            if(updCre.find(val => val.id == action.payload.id)){
                updCre.map(val => val.id == action.payload.id
                    ? val.credit_note = action.payload.credit_note
                    : val);
            }else{
                updCre.push(action.payload);
            }
            return {...state, Contacts: updCre };
        case 'update_credit_note':
            const updCre2 = state.Contacts;
                updCre2.map(val => val.id == action.payload.id
                    ? val.credit_note += parseFloat(action.payload.input)
                    : val);
            return {...state, Contacts: updCre2 };
        default:
            return state;
    }
};

const readContacts =  (dispatch) => {
    return  async () =>{ 
        try{           
            const response = await Api.get('/readContacts');           
            dispatch({ type:'read_contacts', payload: {data:response.data,  opened:response.request.OPENED} });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al cargar contactos \n('+err.message+').'});
        }
    };
};

const createContact =  (dispatch) => {
    return  async (req) =>{ 
        try{          
            const response = await Api.post('/createContact', req);           
            dispatch({ type:'create_contact', payload: response.data });
            //navigate('Contact');
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al crear contacto \n('+err.message+').'});
        }
    };
};

const updateContact =  (dispatch) => {
    return  async (req) =>{ 
        try{          
            await Api.put('/updateContact', req);           
            dispatch({ type:'update_contact', payload: req });
            //navigate('Contact');
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al actualizar contacto \n('+err.message+').'});
        }
    };
};

const deleteContact =  (dispatch) => {
    return  async (req) =>{ 
        try{          
            await Api.post('/deleteContact', req);           
            dispatch({ type:'delete_contact', payload: req });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al eliminar contacto \n('+err.message+').'});
        }
    };
};

const readAccount =  (dispatch) => {
    return  async () =>{ 
        try{           
            const response = await Api.get('/readAccount');           
            dispatch({ type:'read_account', payload: response.data });
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al cargar cuenta \n('+err.message+').'});
        }
    };
};

const updateAccount =  (dispatch) => {
    return  async (req, nav) =>{ 
        try{          
            await Api.put('/updateContact', req);           
            dispatch({ type:'update_account', payload: req });
            nav();
        }catch (err){
            console.log(err.message);
            dispatch({type: 'error', payload:'* Error al actualizar cuenta \n('+err.message+').'});
        }
    };
};

const readCreditNote = (dispatch) => {
    return  async (req) =>{ 
        try{            
            const response = await Api.post('/readCreditNote', req);
            dispatch({ type:'read_credit_note', payload: response.data });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al obtener nota de crédito \n('+err.message+').'});
        }
    };
};

const updateCreditNote = (dispatch) => {
    return  async (req) =>{ 
        try{            
            await Api.post('/updateCreditNote', req);
            dispatch({ type:'update_credit_note', payload: req });
        }catch (err){
            dispatch({type: 'error', payload:'* Error al obtener nota de crédito \n('+err.message+').'});
        }
    };
};

export const {Provider , Context} = createDataContext(
    ContactReducer,
    {readContacts, createContact, updateContact, deleteContact, readAccount, updateAccount,
        readCreditNote, updateCreditNote},
    { Contacts: [], Account:null, errorMessage: '', opened:0}
);