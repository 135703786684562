import React  from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import './App.css';
import "./styles.css";

import "./css/navigation.css";
import "./css/filter.css";
import "./css/products.css";
import "./css/wishlist.css";
import "./css/cart.css";
import "./css/toast.css";
import "./css/confirmation.css";
import "./css/offices.css";
import "./css/foot.css";

import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as ConfigProvider } from './context/ConfigContext';
import { Provider as ProductProvider } from './context/ProductContext';
import { Provider as OrderProvider } from './context/OrderContext';
import { Provider as AddressProvider } from './context/AddressContext';
import { Provider as ContactProvider } from './context/ContactContext';

//import AuthLoadScreen from './screens/AuthLoadScreen';
import SigninScreen from './screens/SigninScreen';
import SignupScreen from './screens/SignupScreen';
import ProductScreen from './screens/ProductScreen';
import ProductCategorieScreen from './screens/ProductCategorieScreen';
import ProductDetailScreen from './screens/ProductDetailScreen';
import ProductFavoriteScreen from './screens/ProductFavoriteScreen';
import ScanScreen from './screens/ScanScreen';
import BasketBigScreen from './screens/BasketBigScreen';
import BasketConfirmationScreen from './screens/BasketConfirmationScreen';
import BasketAddScreen from './screens/BasketAddScreen';
import ConfirmationPayScreen from './screens/ConfirmationPayScreen';
import BasketCompleteScreen from './screens/BasketCompleteScreen';
import OrderScreen from './screens/OrderScreen';
import OrderDetailScreen from './screens/OrderDetailScreen';
import AccountScreen from './screens/AccountScreen';
import AccountModifyScreen from './screens/AccountModifyScreen';
import AddressScreen from './screens/AddressScreen';
import AddressCreateScreen from './screens/AddressCreateScreen';
import AddressModifyScreen from './screens/AddressModifyScreen';
import OfficesScreen from './screens/OfficesScreen';
import OfficesCreateScreen from './screens/OfficesCreateScreen';
import OfficesModifyScreen from './screens/OfficesModifyScreen';
import FAQScreen from './screens/FAQScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import Footer from './components/Footer';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ConfigProvider>
          <ProductProvider>
            <OrderProvider>
              <AddressProvider>
                <ContactProvider>
                  
                  <Routes>
                    <Route path="/" element={<ProductScreen />}></Route>
                    
                    <Route path='signin' element={<SigninScreen/>}/>
                    <Route path='signup' element={<SignupScreen/>}/>
                    <Route path='products' element={<ProductScreen/>}/>
                    <Route path='productcategorie/:id' element={<ProductCategorieScreen/>}/>
                    <Route path='product_favorite' element={<ProductFavoriteScreen/>}/>
                    <Route path='scan' element={<ScanScreen/>}/>
                    <Route path='basket' element={<BasketBigScreen/>}/>
                    <Route path='productdetail/:id' element={<ProductDetailScreen/>}/>
                    <Route path='confirmation' element={<BasketConfirmationScreen/>}/>
                    <Route path='complete' element={<BasketCompleteScreen/>}/>
                    <Route path='basket_add' element={<BasketAddScreen/>}/>
                    <Route path='orders' element={<OrderScreen/>}/>
                    <Route path='order_detail' element={<OrderDetailScreen/>}/>
                    <Route path='confirmation_pay' element={<ConfirmationPayScreen/>}/>
                    <Route path='account' element={<AccountScreen/>}/>
                    <Route path='account_modify' element={<AccountModifyScreen/>}/>
                    <Route path='address' element={<AddressScreen/>}/>
                    <Route path='address_create' element={<AddressCreateScreen/>}/>
                    <Route path='address_modify' element={<AddressModifyScreen/>}/>
                    <Route path='offices' element={<OfficesScreen/>}/>
                    <Route path='office_create' element={<OfficesCreateScreen/>}/>
                    <Route path='office_modify' element={<OfficesModifyScreen/>}/>
                    <Route path='faqs' element={<FAQScreen/>}/>
                    <Route path='privacy' element={<PrivacyScreen/>}/>
                    <Route path="*" element={<div className='not_found'>Not Found</div>} />

                  </Routes>
                  <Footer/>

                  </ContactProvider>
                </AddressProvider>
            </OrderProvider>
          </ProductProvider>
        </ConfigProvider>
      </AuthProvider>
  </BrowserRouter>
  );
}

export default App;
