import { useContext, useState } from "react";
import { Context as ProductContext } from '../context/ProductContext';
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineMinus, HiOutlinePlusSm } from "react-icons/hi";
import logo from '../assets/logo2.png';

export function Filter() {
  const { state, setToggleFilter } = useContext(ProductContext);
  const [catId, setCatId] = useState(0);
  const [subCatId, setSubCatId] = useState(0);
  const navigate = useNavigate();

  return (
    <div className="filter__wrapper">
      <div
        className="filter__background"
        onClick={() => setToggleFilter()
        }
      ></div>
      <div className="filter__content">
        <div className="filter__nav">
          <img src={logo} alt="logo" className="navBar__logo" />
          <div className="filter__header">Menú</div>
          <AiOutlineClose
            className="filter__close"
            onClick={() => setToggleFilter()
            }
          />
        </div>

        <div className="filter__list">
          {state.Categories.map((value, key) => {
            return (
              <div key={key}>
                <div className="filter_row"
                  onClick={() => catId === value.id ? (setCatId(0), setSubCatId(0)) : setCatId(value.id)}>

                  {catId === value.id
                    ? <HiOutlineMinus size={13} />
                    : <HiOutlinePlusSm size={13} />
                  }
                  <div className="filter_cat">{value.name}</div>
                </div>
                <div>
                  {state.subCategories.filter(val => val.categories_id === catId && val.categories_id === value.id)
                    .map((val, k) => {
                      return (
                        <div key={k}>
                          <div className="filter_row_sub"
                            onClick={() => {
                              setToggleFilter();
                              navigate("/productcategorie/" + val.name, { state: { item: val } });
                            }}
                          >
                            <HiOutlineMinus size={13} color='gray' />
                            <div className="filter_suCat">{val.name}</div>

                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            );
          })
          }
        </div>

      </div>
    </div>
  );
}
