import React, { useContext, useEffect, useState } from "react";
import { Context as AddressContext } from '../context/AddressContext';
//import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext';
import { AiFillPrinter } from "react-icons/ai";
import '../css/exportPDF.css';

const ExportPDF = ({ id, auto }) => {
    const {state:{Product}} = useContext(ProductContext);
    const {state:{Orders}} = useContext(OrderContext);
    const {state:{business}} = useContext(ConfigContext);
    //const {state:{rol}} = useContext(AuthContext);
    const {state:{Offices}} = useContext(AddressContext);
    const [visible, setVisible] = useState(false);
    const [printing, setPrinting] = useState(false);

    let render = false;

    useEffect(() => {
        if(render === false){
            if(auto === true){
                setVisible(true);
                ContentPrint(1);
            }
        }
        render = true;
    },[]);

    const printFunction = () => {
        if(printing === false){
            window.print();
        }
    };

    const moneyFormat = (value) => {
        let val = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return val;
    };



    //const ContentPrint = async (option) => {
        function ContentPrint({ option }) {
           
            const items = Orders.find(val => val.id === id);
            if(items === undefined){
                return null;
            }

            const getOffice = Offices.find(val => val.id === items.address_id);

            let date;
            if(items.delivery !== undefined){
                date = new Date(items.delivery);
            }else{
                date = new Date();
            }
            let fullDate = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()} 
            ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;

            let finalTotal = 0;
            let itemsTotal = 0;
    
            let payStatus = 'Pagado';
            if(items.status !== undefined){
                payStatus = items.status;
            }
            if(payStatus === 'Enviado'){
                payStatus = 'Pagado';
            }
    
            let name = items.name;
            if(items.lastname){
                name += ' '+items.lastname;
            }
    
            let method;
            let info1 = '';
            let info2 = '';
            let fee = 0;
            let notCre = 0;
            if(items.pay_method !== undefined){
                method = items.pay_method;
                if(method === 'Mercado pago'){
                    fee = 3;
                }else if(method === 'Paypal'){
                    fee = 3;
                }else if(method === 'Combinado'){
                    info1 = `1.- ${items.transaction_details.method1} ${moneyFormat(items.transaction_details.amount1)}`; 
                    info2 = `2.- ${items.transaction_details.method2} ${moneyFormat(items.transaction_details.amount2)}`;
                    if(items.transaction_details.method1 === 'Nota de crédito'){
                        notCre = parseFloat(items.transaction_details.amount1);
                    }
                }
            }else{
                if(items.card.id === 1){
                    method = 'Tarjeta';
                }else if(items.card.id === 2){
                    method = 'Efectivo';
                }else if(items.card.id === 3){
                    method = 'SPEI';
                }else if(items.card.id === 4){
                    method = 'Terminal';
                }else if(items.card.id === 5){
                    method = 'Credito';
                }else if(items.card.id === 6){
                    method = 'Combinado';
                    info1 = `1.- ${items.card.transaction.method1} ${moneyFormat(items.card.transaction.amount1)}`; 
                    info2 = `2.- ${items.card.transaction.method2} ${moneyFormat(items.card.transaction.amount2)}`;
                    if(items.card.transaction.method1 === 'Nota de crédito'){
                        notCre = parseFloat(items.card.transaction.amount1);
                    }
                }else if(items.card.id === 7){
                    method = 'Mercado pago';
                    fee = 3;
                }else if(items.card.id === 8){
                    method = 'Paypal'; 
                    fee = 3;
                }
                else if(items.card.id === 9){
                    method = 'Nota de crédito';
                }
            }
    

            let ItemsGroup = [];
            
            //await items.items.reduce(async (promise, item) => {
            items.items.map((item) => { 
                //await promise;
            let product = Product.find(value => value.colors
                .find(val => val.id === item.productcolor_id));
            let color = product.colors.find(value => value.sizes.find(val => val.id === item.id));
            let size = color.sizes.find(value => value.id === item.id);
    
            let price = item.price;
            let discount = item.discount;
            //let corrida = item.discount_corrida; 
    
            let finalPrice = price;
            
            if(item.show_corrida === true || item.discount_corrida){
                let newCorrida = items.discount_corrida;
                if(item.discount_corrida){
                    newCorrida = item.discount_corrida;
                }
                finalPrice = price - newCorrida;
    
            }else if(discount > 0){
                price = Math.round(price + ((price * items.sellonline) / 100)); 
                finalPrice = (price -discount).toFixed(0); 
            }
            else if(items.discount){
                finalPrice = price -  items.discount;
            }else{
              price = Math.round(price + ((price * items.sellonline) / 100)); 
              finalPrice = price.toFixed(0); 
            }
            
            let total = finalPrice * item.items;
            finalTotal += total;    
            itemsTotal += item.items; 
    
            if(ItemsGroup.find(val => val.id === color.id)){
                ItemsGroup.map(val => val.id === color.id
                    ? {...val, 
                        cant: (val.cant += item.items),
                        total: (val.total += total)
                    }
                    : val);
    
            }else{
                ItemsGroup.push({
                    id: color.id,
                    product:product.name,
                    color:color.name,
                    cant: item.items,
                    unitPrice: finalPrice,
                    total
                });
            }
    
            
        });

    
            
            if(items.discount_money !== null){
                finalTotal = (finalTotal - items.discount_money);
            }
    
            let services = items.service;
            if(items.services !== undefined){
                services = items.services;
            }
            //finalTotal += services !== 0 ? services : 0;
            if(services !== 0){
                finalTotal += services;
            }
    
            let te;
            if(fee > 0){
                te = (finalTotal * (3 / 100))
                finalTotal += te;
            }
            if(notCre > 0){
                finalTotal -= notCre;
            }

            setTimeout(() =>  printFunction(), 1000);
            setPrinting(true);
            return(
                <div className="export_App">
                    <div className="export_contain">
                        <div className="export_title">{business[0].name}</div>
                        <div className="export_subTitle">SAPL620614JD7</div>
                        
                        {getOffice !== undefined &&
                        <div>
                            <div className="export_text export_Top">{getOffice.name}</div>
                            <div className="export_text">{getOffice.address}</div>
                            <div className="export_text">{getOffice.city} {getOffice.state1}</div>
                            <div className="export_text">Tel: {getOffice.phone}</div>

                        </div>
                        }
        
                        <div className="export_text export_Top">Fecha: {fullDate} </div>
                        <div className="export_text">Folio: {items.id} </div>
                        <div className="export_text">{name}</div>
                        
        
                        <div className="export_row export_Top">
                            <div className="export_column_header_cant">Cant</div>
                            <div className="export_column_header_desc">Descripción</div>
                            <div className="export_column_header">P. Unitario</div>
                            <div className="export_column_header">Importe</div>
                        </div>
                        
                        {ItemsGroup.map((value,key) => {
                            return(
                            <div key={key} className="export_row">
                                <div className="export_column_item_cant">{value.cant}</div>
                                <div>
                                    <div className="export_column_items_desc">{value.product}</div>
                                    <div className="export_column_items_desc">{value.color}</div>
                                </div>
                                <div className="export_column_items">{moneyFormat(value.unitPrice)}</div>
                                <div className="export_column_items">{moneyFormat(value.total)}</div>
                            </div>
                            );
                        })}
                        {items.discount_money > 0 &&
                        <div className="export_row">
                            <div className="export_column_subTotal_desc"></div>
                            <div className="export_column_subTotal">Descuento adicional</div>
                            <div className="export_column_subTotal">{moneyFormat(items.discount_money)}</div>
                        </div>
                        }

                        {services > 0 &&
                        <div className="export_row">
                            <div className="export_column_subTotal_desc"></div>
                            <div className="export_column_subTotal">Servicio a domicilio</div>
                            <div className="export_column_subTotal">{moneyFormat(services)}</div>
                        </div>
                        }

                        {fee > 0 &&
                        <div className="export_row">
                            <div className="export_column_subTotal_desc"></div>
                            <div className="export_column_subTotal">{fee}%</div>
                            <div className="export_column_subTotal">{moneyFormat(te)}</div>
                        </div>
                        }

                        {notCre > 0 &&
                        <div className="export_row">
                            <div className="export_column_subTotal_desc"></div>
                            <div className="export_column_subTotal">Nota de Crédito</div>
                            <div className="export_column_subTotal">{moneyFormat(notCre)}</div>
                        </div>
                        }

                        <div className="export_row">
                            <div className="export_column_total_cant">{itemsTotal}</div>
                            <div className="export_column_total_desc"></div>
                            <div className="export_column_total">TOTAL</div>
                            <div className="export_column_total">{moneyFormat(finalTotal)}</div>
                        </div>
                        
                        <div className="export_row export_Top"> 
                            <div className="export_text">Pago: </div>
                            <div className="export_text"> {method} </div>
                            <div className="export_text"> ({payStatus})</div>
                        </div>
                        
                        <div className="export_text export_Top">22 días para cambios o devoluciones,</div>
                        <div className="export_text">debido a su artesanía en herrajes y</div>
                        <div className="export_text">pedrería no hay devoluciones.</div>
                        <div className="export_thanks export_Top">*Gracias por su compra*</div>
                        <br/>
                        
                        <br/>
                    </div>
                </div>
            );
        }

    return(
        <div>
            {visible && <ContentPrint />}
            {/* 
            <div className="no-print" onClick={() => {
                setVisible(true);
                ContentPrint(1);
                }}>IMPRIMIR</div>

            <AiFillPrinter className="order_icon no-print" onClick={() => {
                setVisible(true);
                ContentPrint(1);} }/>
            */}
        </div>
    );
};

export default ExportPDF;