import React, { useContext, useEffect, useState } from "react";
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as AddressContext } from '../context/AddressContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import ProductBoxH from '../components/ProductBoxH';
import { Navigation } from '../components/navigation';
import { Cart } from '../components/cart';
import { Filter } from '../components/filter.js';
import { useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const ScanScreen = () => {

    const { state: { id, rol, load }, tryLocalSignin } = useContext(AuthContext);
    const { state: { Product, baseURL, toggleCart, toggleFilter }, readProductScan, getProductDetail } = useContext(ProductContext);
    const { state: { SelectedItems, addItemHold },
        addItem5, deleteItem, getBasketV2 } = useContext(OrderContext);
    const { state: { Offices } } = useContext(AddressContext);
    const { state: { showtag, inv_inf, sellonline }, readConfig } = useContext(ConfigContext);
    const [input, setInput] = useState('');
    const [scan, setScan] = useState({});
    const [findCode, setFindCode] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
        if (load === false) {
            //navigate("/", { replace: true });
            tryLocalSignin({ business_id: 5 });
            readConfig(5);
            getBasketV2();
        }
    }, []);

    const handleChange = ({ target: { value } }) =>
        setInput(value);

    let Selected = null;
    const assignValues = (data, product) => {
        if (product !== null && data !== '') {
            let color = product.colors.find(value => value.sizes.find(val => val.code === data && val.quantity > 0));
            let size = color.sizes.find(value => value.code === data && value.quantity > 0);
            setScan(size);
            setFindCode('');


            Selected = SelectedItems.find(val => val.id === size.id);
            if (!addItemHold) {
                if (Selected === undefined) {
                    if (id !== null) {
                        let select = size;
                        select.account_id = id;

                        if (!addItemHold) {
                            if (select.quantity > 0) {
                                addItem5({ select, add: 1, inv_inf });
                            }

                        }
                    }
                } else {
                    addItem5({ select: Selected, add: 1, inv_inf });
                }
            }


        } else {
            setFindCode(data);
            setScan({});
        }
        setInput('');
    };

    if (scan.id !== undefined) {
        Selected = SelectedItems.find(val => val.id === scan.id);
    }

    return (
        <div className="App">
            {toggleCart && <Cart />}
            {toggleFilter && <Filter />}
            <Navigation />
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon" />
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title">Scan & Go</div>
                </div>
            </div>
            <div className="productTitle2">Scan & Go</div>

            <div className="productScanList">
                <div className="productScanCard">
                    <input
                        type="text"
                        name="input"
                        id="input"
                        value={input}
                        onChange={handleChange}
                        placeholder="Code"
                        className="scanInput"
                        autoFocus
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                setTimeout(() => readProductScan(assignValues, { id: e.target.value }), id === 539 ? 500 : 0)
                            }
                        }}
                    />
                    {findCode !== '' &&
                        <div>
                            <div className="productTime">{findCode}</div>
                            <div className="product_text_error">Código no registrado</div>
                        </div>
                    }

                    {(Selected !== null && Selected !== undefined) &&
                        <div className="productScanBox">
                            <ProductBoxH
                                Product={Product.find(value => value.colors
                                    .find(val => val.id === scan.productcolor_id))}
                                item={Selected}
                                addItem={addItem5}
                                addItemHold={addItemHold}
                                URL={baseURL}
                                editable={true}
                                deleteItem={deleteItem}
                                getProductDetail={getProductDetail}
                                rol={rol}
                                sellonline={sellonline}
                                inv_inf={inv_inf}
                            />

                        </div>
                    }
                    {SelectedItems.length > 0 &&
                        <div className="productScanBtn">
                            <div className="primary__button cart" onClick={() => navigate('/Basket')}>Carrito de compras</div>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
};

export default ScanScreen;