import React, { useContext, useEffect } from 'react';
import { Context as AddressContext } from '../context/AddressContext';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { useNavigate } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const AddressScreen = () => {
    const {state, readAddress, deleteAddress} = useContext(AddressContext);
    const {state:{rol}} = useContext(AuthContext);
    const {state:{business}} = useContext(ConfigContext);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        readAddress();
    },[]);
    
    return (
        <div className='App'>
            <div className="navContainer">
                <div className="navBar">
                {business.length > 0 && <div className="headerNameCenter">{business[0].name}</div>}
                {(rol !== 'User' && rol !== null) &&
                    <div className='nav_add_right' onClick={() => navigate('/address_create')}>AGREGAR</div>
                }
                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/account', { replace: true })}>Cuenta</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Mis Direcciones</div>
                </div>
            </div>
            <div className='offices_Title'>Mis Direcciones</div>
            <div className='offices_List'>
                {state.Address.map((item, key) => {

                
                        return (
                            <div key={key} className="offices_Card">
                                <div 
                                    onClick={()=> {
                                        if(rol !== 'User' && rol !== null){
                                            navigate("/address_modify",{state:{item}});
                                        }
                                    }}
                                >
                                    <BiMap className='offices_big_icon' />
                                    <div>
                                        <div className='offices_Text'>{item.street} #{item.num_ext}</div>
                                        <div className='offices_Text'>{item.suburb}</div>
                                        <div className='offices_Text'>{item.city}, {item.state1}</div>
                                        <div className='offices_Text'>{item.cp}</div>
                                        <div className='offices_Text'>{item.reference}</div>
                                    </div>                                  
                                </div>
                            </div>
                            );
                        })}
                           
                
            </div>     
            {state.Address.length === 0 && <div className="style_full_height"/>}
        </div>
    );
};


export default AddressScreen;