import React, { useState } from "react";

import logo from '../assets/logo.png';

const UserForm = ({item, onSubmit,isRegister, nav, rol, errorMessage,resetMessage}) => {
    const [contact, setContact] = useState(item);
    const [newPassword, setNewPassword]= useState({pass:item?.password,msj:'', flag: false});
    const [message, setMessage] = useState({});
    const [showActivity, setShowActivity] = useState(false);
    const [changePass, setChangePass] = useState(false);

    const validForm = () => {
        let obj = {};
        let flag = false;

        if(!contact.name){
            flag = true;
            obj.name = '* Nombre obligatorio';
        }
        if(!contact.lastname){
            flag = true;
            obj.lastname = '* Apellido obligatorio';
        }
        if(contact.phone){
            if(contact.phone.length < 10 || contact.phone.length >= 11){
                flag = true;
                obj.phone = '* Teléfono obligatorio con 10 digitos';
            }
        }
        if(!contact.phone){
            flag = true;
            obj.phone = '* Teléfono obligatorio';
        }
        if(!contact.email){
            flag = true;
            obj.email = '* Email obligatorio';
        }
        if(isRegister){
            if(!contact.password){
                flag = true;
                obj.pass = '* Contraseña obligatorio';
            }else{
                if(contact.password.length < 5){
                    flag = true;
                    obj.pass = '* Contraseña obligatorio con más de 5 caracters';
                }
            }
        
            if(contact.password !== contact.newPassword){
                flag = true;
                obj.pass2 = '* No coincide con la contraseña';
            }
        }

        setMessage(obj);
        return flag;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!validForm()){
            onSubmit(contact, nav, setShowActivity);
        }

      };
    
      const handleChange = ({ target: { value, name } }) =>
        setContact({ ...contact, [name]: value });

    return(
        <div className="App">
            <div className="list">
                <div className="signupCard">
                    <form
                        onSubmit={handleSubmit}   
                    >
                        <div>
                            <img src={logo}  className="signinImage" alt="logo"/>
                        </div>
                        <div>
                        
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={contact.name}
                                onChange={handleChange}
                                placeholder="Nombre"
                                className="inputSignup"
                            />
                            <div className="error_input">{message.name}</div>
                        </div>
                        <div>
                            <input
                                type="text"
                                name="lastname"
                                id="lastname"
                                value={contact.lastname}
                                onChange={handleChange}
                                placeholder="Apellidos"
                                className="inputSignup"
                            />
                            <div className="error_input">{message.lastname}</div>
                        </div>
                        <div>
                            <input
                                type="number"
                                name="phone"
                                id="phone"
                                value={contact.phone}
                                onChange={handleChange}
                                placeholder="Teléfono"
                                className="inputSignup"
                            />
                            <div className="error_input">{message.phone}</div>
                        </div>
                        <div>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={contact.email}
                                onChange={handleChange}
                                placeholder="Correo Electronico"
                                className="inputSignup"
                            />
                            <div className="error_input">{message.email}</div>
                        </div>

                        {(isRegister || changePass) &&
            
                        <div>
                            <div>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={handleChange}
                                    placeholder="Contraseña"
                                    className="inputSignup"
                                />
                                <div className="error_input">{message.pass}</div>
                            </div>
                            <div>
                                <input
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    onChange={handleChange}
                                    placeholder="Confirmar Contraseña"
                                    className="inputSignup"
                                />
                                <div className="error_input">{message.pass2}</div>
                            </div>
                        </div>
                        }
                        
                        {showActivity && <div class="loader"></div>}
                        <div className="error_label">{errorMessage}</div>

                        <div>
                            <button
                                type="submit"
                                className="button"
                            >
                                {isRegister ? 'Crear cuenta' : 'Actualizar'}
                            </button>
                        </div>
                    </form>     
                </div>
            </div>       
      </div>
    );
};

export default UserForm;