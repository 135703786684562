import React, { useContext } from 'react';
import { Context as AddressContext } from '../context/AddressContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import OfficesForm from '../components/OfficesForm';

const OfficesModifyScreen = () => {
    const {state, addOffices} = useContext(AddressContext);
    const {state:{business_id}} = useContext(ConfigContext);
    return (
        <div>
            <OfficesForm
                item={{}}
                onSubmit={addOffices}
                errorMessage={state.errorMessage}
                business_id={business_id}
            />
        </div>
    );
};

export default OfficesModifyScreen;