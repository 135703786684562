import React, { useContext, useState } from "react";
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext';
import { Context as AddressContext } from '../context/AddressContext';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import {useLocation, useNavigate} from 'react-router-dom';
import { Navigation } from '../components/navigation';
import { Cart } from '../components/cart';
import { Filter } from '../components/filter.js';
import logo from '../assets/logo.png';
import { FaPlus, FaMinus, FaTrash, FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";
import { MdBusiness } from "react-icons/md";

const BasketAddScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const product_id = location.state.id;
    const color_id = location.state.color_id;

    const {state} = useContext(ProductContext);
    const {state:{addItemHold, SelectedItems, account_id}, addItem5, deleteItem} = useContext(OrderContext);
    const {state:{Offices, OfficeSup}} = useContext(AddressContext);
    const {state:{id}} = useContext(AuthContext);
	const {state:{inv_inf}} = useContext(ConfigContext);
    const [buttonActivity, setButtonActivity] = useState({show:false, id:null});
    const [office, setOffice] = useState(OfficeSup);
    const [holding, setHolding] = useState(false);

    return(
        <div className="App">
            {state.toggleCart && <Cart/>}
            {state.toggleFilter && <Filter />}
            <Navigation/>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate(-1)}>back</div>
                </div>
            </div>
             <div className="productDetailList">
                <div className="productAddCard">
            {state.Product.filter(val => val.id === product_id).map((value,key) => {
                return(
                    <div key={key}>
                        {value.colors.filter(val => val.id === color_id).map((val,k) => {
                            let imagen =   val.images[0] !== null 
                            ? state.baseURL+val.images[0]
                            : logo;
                            return(
                                <div key={k}>
                                    {val.sizes.map((v,k1) => {
                                        let findItem = SelectedItems.find(item => item.id === v.id && item.show_corrida == true);
                                        if(findItem){
                                            findItem.quantity = findItem.items
                                        }

                                        return(
                                            <div key={k1} className="cart_Row productAddSpace">
                                            <div>
                                                <img src={imagen} alt={''} className="cart__item--image"/>
                                             </div>   
                                             <div className="productAddleft">
                                                <div className="cart__item--name">{value.name}</div>
                                                <div className="cart_Row">
                                                    <div className="cart__item--price">{val.name}</div>
                                                    <div className="product_circle_color" style={{backgroundColor:val.color, marginLeft:5}}/>
                                                </div>
                                                <div className="cart__item--price">Talla {v.size}</div>
                                                <div className="cart_Row">
                                                    <MdBusiness size={20} color="#e048db" />
                                                    <div className="cart__item--price">{Offices.find(item => item.id == v.offices_id).name}</div>
                                                </div> 
                                            </div>
                                            {addItemHold && v.id === buttonActivity.id
                                            ? null
                                            :
                                            <div >                                                 
                                                    {findItem
                                                    ? 
                                                    <div className="box_quantity--container">
                                                    <FaMinus
                                                    className="cart__remove--item"
                                                    onClick={() =>
                                                        {if(!addItemHold && !holding){
                                                        setHolding(true);
                                                        addItem5({select:findItem, add:-1, inv_inf});
                                                        setTimeout(() => setHolding(false), 600);
                                                    } } //dispatch({ type: "DECREASE_QUANTITY", payload: item })
                                                    }
                                                    />
                                                    <div className="cart__item--quantity">{findItem.items}</div>
                                                    <FaPlus
                                                    className="cart__add--item"
                                                    onClick={() =>
                                                        {if(!addItemHold && !holding){
                                                        setHolding(true);
                                                        addItem5({select:findItem, add:1, inv_inf});
                                                        setTimeout(() => setHolding(false), 600);
                                                    } }//dispatch({ type: "INCREASE_QUANTITY", payload: item })
                                                    }
                                                    />
                                                    </div>

                                                    : 
                                                    <div>
                                                        {v.quantity > 0
                                                        ? <div className="product_corrida_btn"  onClick={()=> {
                                                            if(!addItemHold){
                                                                setButtonActivity({show:true, id:v.id});
                                                                let dummy = v;
                                                                dummy.unit = value.unit;
                                                                dummy.show_corrida = true;
                                                                dummy.account_id = account_id == null ? id : account_id.id;
                                                                addItem5({
                                                                    select:dummy, inv_inf
                                                                });
                                                            }
                                                            }}
                                                            >
                                                            <div className="product_corrida_txt">Agregar</div>
                                                        </div>
                                                        : <div className="cart__item--price">{v.quantity} pz</div>}
                                                    </div>
                                                    }
                                            </div>    
                                        }          
                                        </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
                </div>
            </div>
        </div>
    );
};

export default BasketAddScreen;