import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const OfficesForm = ({ item, business_id, onSubmit, errorMessage }) => {

    const [form, setForm] = useState(item);
    const [fresh, setRefresh] = useState(false);
    const [message, setMessage] = useState({});
    const [showActivity, setShowActivity] = useState(false);

    const navigate = useNavigate();

    const nav = () => {
        //navigate("/Offices", { replace: true });
        navigate(-1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            form.business_id = business_id;
            onSubmit(form, nav, setShowActivity);
            
        } catch (error) {

        }
    };
    
    const handleChange = ({ target: { value, name } }) =>
    setForm({ ...form, [name]: value });

    return(
        <div className="App">
            <div className="navContainer">
                <div className="navBar">
                <div className="headerNameCenter">Zapatillas May</div>
                
                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/account', { replace: true })}>Cuenta</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/offices', { replace: true })}>Sucursales</div>
                </div>
            </div>
            <div className="list">
                <div className="signupCard">
                    <form
                        onSubmit={handleSubmit}   
                    >
                        <div>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={form.name}
                                onChange={handleChange}
                                placeholder="Nombre"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="number"
                                name="phone"
                                id="phone"
                                value={form.phone}
                                onChange={handleChange}
                                placeholder="Teléfono"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="address"
                                id="address"
                                value={form.address}
                                onChange={handleChange}
                                placeholder="Dirección"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                value={form.city}
                                onChange={handleChange}
                                placeholder="Ciudad"
                                className="inputSignup"
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                name="state1"
                                id="state1"
                                value={form.state1}
                                onChange={handleChange}
                                placeholder="Estado"
                                className="inputSignup"
                                required
                            />
                        </div>

                        <div>
                        {showActivity && <div class="loader"></div>}

                            <button
                                type="submit"
                                className="button"
                            >
                                Guardar
                            </button>
                        </div>
                    </form>     
                </div>
            </div>       
      </div>
    );
};

export default OfficesForm;