import React from "react";
import { BsCircleFill, BsHeart, BsHeartFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
import { GiPriceTag } from "react-icons/gi";


const ProductBox = ({ item, getProductDetail, baseURL, Favorites, updateFavorites, showtag, type }) => {

    const navigate = useNavigate();

    let findColor =  item.colors.find(val => val.id);
    let findSize =  undefined;//findColor.sizes.find(val =>  val.id);
    let findImage = findColor.images[0];

    let imagen =  logo;
    if(findImage !== undefined){
        imagen = baseURL+findImage;
    }
    
    
/*
    const moneyFormat = (value) => {
        let val = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' MXN';
        return val;
    };
    */
    return (
        <div className="productCard" onClick={() => {
            getProductDetail({id:item.id});
            navigate("/productdetail/"+item.id,{state:{id:item.id}});
        }}>
            <img src={imagen} alt={''} className="productImage"></img>
            
            {Favorites.account_id !== null &&
            <div className="productCard_float" onClick={(e) => {
                e.stopPropagation();
                updateFavorites(Favorites);
            }}>
                {Favorites.id !== null
                    ? <BsHeartFill color="red"/>
                    : <BsHeart />
                }
            </div>
            }

            {type === 'new' &&
                <div className="productCard_float_new">
                    PRODUCTO NUEVO
                </div>
            }
            {type === 'renew' &&
                <div className="productCard_float_renew">
                    RELANZAMIENTO
                </div>
            }

            {(item.discount > 0 && showtag) &&
            <div className="productCard_float_oferta" style={type === 'new' || type === 'renew' ? {top: 23} : {}}>
                !EN OFERTA¡
            </div>
            }

            <div className="productCard__content">
                <h3 className="productName">{item.name}</h3>
                {/* 
                <div className="displayStack__1">
                    <div className="productPrice">{moneyFormat(item.price)}</div>
                </div>
                */}
                <div className="displayStack_box">
                    <div className="productRating">
                        {item.colors.map((value, index) => (
                        <div key={index} className="product_circle_color" style={{backgroundColor:value.color}} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProductBox;