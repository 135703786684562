import React, { useContext, useEffect } from "react";
import { Context as ContactContext } from '../context/ContactContext';
import UserForm from '../components/UserForm';
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const AccountModifyScreen = () => {
    const {state, readAccount, updateAccount } = useContext(ContactContext);
    const navigate = useNavigate();

    const nav = () => {
        navigate("/account", { replace: true });
    };

    useEffect(() => {
        readAccount();
    }, []);

    return(
        <div>
           <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/account', { replace: true })}>Cuenta</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Mi información</div>
                </div>
            </div>
            {(state.Account !== null && state.Account !== undefined)
            ? 
                <UserForm 
                    item={state.Account}
                    onSubmit={updateAccount}
                    isRegister={false}
                    nav={nav}
            />
            : <div class="loader"/>
            }
        </div>
    );
};

export default AccountModifyScreen;