import axios from 'axios';

const instance =  axios.create({
    baseURL:'https://shopmay.com.mx:3115'
    //baseURL:'http://192.168.1.73:3015'
    //baseURL:'http://localhost:3015'
});

instance.interceptors.request.use(
    async config => {
      const obj = localStorage.getItem("eCommerce_ReactJS_005");
      const item = JSON.parse(obj);

      if (item?.token) {
        config.headers.Authorization = `Bearer ${item.token}`;
      }
      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );

export default instance;