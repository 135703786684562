import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Context as OrderContext } from '../context/OrderContext';
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

const ConfirmationPayScreen = () => {
    const { addOrder } = useContext(OrderContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showActivity, setShowActivity] = useState(false);
    const navigate = useNavigate();

    const nav = () => {
        navigate("/complete", { replace: true });
    };

    useEffect(() => {
        if (searchParams.get("status") === 'approved') {
            const obj = localStorage.getItem("Pre_Code_005");
            let item = JSON.parse(obj);
            if (item !== null) {
                item.card.payment_status = 'Realizado';
                addOrder(item, nav, setShowActivity);
                localStorage.removeItem('Pre_Code_005');
            }
        } else {
            setTimeout(() => nav(), 1500);
        }
    }, []);

    return (
        <div className="App">
            {searchParams.get("status") === 'approved' &&
                <div className="confirmation_card_confirm_up">
                    <div className="confirmation_confirm_box">
                        <BsFillCheckCircleFill size={54} color='#fff' />
                        <div className="confirmation_confirm_text">Gracias por tu compra!</div>
                        {showActivity && <div className="loader"></div>}
                    </div>
                </div>
            }
            {searchParams.get("status") === 'failure' &&
                <div className="confirmation_card_confirm_up failure_color">
                    <div className="confirmation_confirm_box">
                        <BsFillXCircleFill size={54} color='#fff' />
                        <div className="confirmation_confirm_text">Algo salio mal!</div>
                    </div>
                </div>
            }
            {searchParams.get("status") === 'pending' &&
                <div className="confirmation_card_confirm_up pending_color">
                    <div className="confirmation_confirm_box">
                        <BsFillCheckCircleFill size={54} color='#fff' />
                        <div className="confirmation_confirm_text">Gracias por tu compra!</div>
                        <div className="confirmation_confirm_text">Pago pendiente de revisión!</div>
                    </div>
                </div>
            }
            <div className="confirmation_card_confirm_down"></div>

        </div>
    );
};

export default ConfirmationPayScreen;