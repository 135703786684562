import React, { useContext, useEffect, useState } from "react";
import { Context as AddressContext } from '../context/AddressContext';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as OrderContext } from '../context/OrderContext';
import TotalBox from '../components/TotalBox';
import { useNavigate } from "react-router-dom";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { FaHome, FaStoreAlt, FaTruck, FaCreditCard } from "react-icons/fa";
import { MdWeb, MdStoreMallDirectory } from "react-icons/md";
import { BsCircleFill, BsCircle, BsPlusCircleFill, BsSquare, BsCheckSquare, BsFillCheckCircleFill } from "react-icons/bs";
import ExportPDF from '../components/ExportPDF';

//const FORM_ID = 'payment-form';

const BasketConfirmationScreen = () => {
    const { state, readAddress, readOffices } = useContext(AddressContext);
    const { state: { Address, service, account_id, secure, revision, Card, SelectedItems, discount, sellOnline,
        discountMoney, increaseMoney, amount, comments, rewards_count, email, confirmOrder, printId,
        mercadoToken, validation },
        setAddress, addCard, readCreditNote, addOrder, getMercadoToken, validateOrder } = useContext(OrderContext);
    const { state: { business, ispicking, pickid, isservice, business_id, iscredit, isspei, speiclave, iscash, isterminal,
        creditkey, discount_corrida, sellonline, rewards_days, inv_inf, codigos_postales, ip } } = useContext(ConfigContext);
    const { state: { name, id, rol, load }, readUserData } = useContext(AuthContext);
    const { state: { Product, baseURL } } = useContext(ProductContext);

    const [showSelect1, setShowSelect1] = useState(false);
    const [showSelect2, setShowSelect2] = useState(false);
    const [showSelect3, setShowSelect3] = useState(false);

    const [showPayMet, setShowPayMet] = useState(0);
    const [showActivity, setShowActivity] = useState(false);
    const [paying, setPaying] = useState(false);
    const [showWeb, setShowWeb] = useState(false);
    const [isVisible, setIsVisible] = React.useState(false);
    const [print, setPrint] = useState(false);
    const [messageSubmit, setMessageSubmit] = useState('');

    const navigate = useNavigate();

    let render = false;
    useEffect(() => {
        window.scrollTo(0, 0);
        if (load === false || business.length === 0) {
            navigate("/", { replace: true });
        } else {
            if (render === false) {
                readOffices({ business_id });
                readAddress();
                readUserData({account_id:id});
                if (rol === 'Support' && state.OfficeSup !== null) {
                    setAddress({ ...state.Offices.find(val => val.id === state.OfficeSup), ispicking, noService: false, pack: '' });
                }
            }

            if (showWeb && mercadoToken !== null) {
                window.location.replace(mercadoToken.init_point);
            }

            render = true;
        }
    }, [mercadoToken]);

    if (load === false || business.length === 0) {
        return <div></div>;
    }

    let arrayMethodsMain = [
        { id: 1, name: 'Pago con Tarjeta' },
        { id: 2, name: 'Pago en Efectivo' },
        { id: 3, name: 'Pago con transferencia/depósito' },
        { id: 4, name: 'Pago con Terminal' },
        { id: 5, name: 'Credito' },
        { id: 6, name: 'Pago Combinado' },
        { id: 7, name: 'Mercado pago' },
        { id: 8, name: 'Paypal' }
    ];

    if (!iscredit) {
        arrayMethodsMain = arrayMethodsMain.filter(val => val.id !== 1);
    }
    if ((!iscash || !Address.ispicking)) {
        arrayMethodsMain = arrayMethodsMain.filter(val => val.id !== 2);
    }
    if ((!isspei)) {
        arrayMethodsMain = arrayMethodsMain.filter(val => val.id !== 3);
    }
    if ((!isterminal || !Address.ispicking)) {
        arrayMethodsMain = arrayMethodsMain.filter(val => val.id !== 4);
    }
    if (rol === 'User') {
        arrayMethodsMain = arrayMethodsMain.filter(val => val.id !== 5 && val.id !== 6 &&
            val.id !== 7 && val.id !== 8);
    }


    let arrayMethods = ['Efectivo', 'Terminal', 'SPEI', 'Tarjeta', 'Credito', 'Nota de crédito', 'Mercado pago', 'Paypal'];

    let amount2 = 0;
    let servicio = service;
    if (Address.noService === true || Address.ispicking === true || Address.id === undefined || Address.serviceFree) {
        servicio = 0
    }
    if (Address.ispicking === true) {
        amount2 = parseFloat(amount);
    } else {
        amount2 = parseFloat(amount) + parseFloat(servicio);
    }

    if (secure) {
        //amount2 = parseFloat(amount2) * 1.03;
    }
    /*
    if (Card.id === 1) {
        amount2 += (amount2 * 0.0349) + 4 + (((amount2 * 0.0349) + 4) * 0.16)
    }
    */

    let notCre = 0;
    if (Card.id === 6) {
        if (Card.transaction.method1 === 'Nota de crédito') {
            notCre = parseFloat(Card.transaction.amount1);
            amount2 -= notCre;
        }
    }
    let fee = 0;
    if (Card.id === 7 || Card.id === 8) {
        fee = 3;
        amount2 += (amount2 * (fee / 100));
    }
    amount2 = amount2.toFixed(2);
    let textBtn = '';
    if (revision) {
        textBtn = 'Enviar a revisión';
    } else {
        textBtn = 'Finalizar Compra';
    }
    if (Card.id === 1) {
        textBtn = 'Pagar';
    }
    let increase = 0;
    if (increaseMoney != null) {
        increase = increaseMoney;
    }
    let newReawards_count = rewards_count;
    let rewardsFlag = false;

    let paqueteriaFlag = false;
    if (Address.id) {
        paqueteriaFlag = true;
    }
    if (Address.noService && Address.pack.length < 3) {
        paqueteriaFlag = false;
    }

    /*
    if(Address.ispicking === undefined && rewards_days > 0 
        && (rol === 'User' || account_id !== undefined)){
            newReawards_count++;
            rewardsFlag = true;
            if(newReawards_count > rewards_days){
                newReawards_count = 0;
            }
    }
    */


    if (confirmOrder === true) {
        return (
            <div className="App">
                <div className="no-print">
                    <div className="confirmation_card_confirm_up">
                        <div className="confirmation_confirm_box">
                            <BsFillCheckCircleFill size={54} color='#fff' />
                            <div className="confirmation_confirm_text">Gracias por tu compra!</div>
                        </div>
                    </div>
                    <div className="confirmation_card_confirm_down">

                    </div>
                </div>
                {print &&
                    <ExportPDF id={printId} auto={true} />
                }
            </div>
        )
    }

    const nav = () => {
        navigate("/complete", { replace: true });
    };

    const setLocalStorage = () => {
        SelectedItems.forEach(value => {
            let product = Product.find(val => val.colors.find(val => val.id === value.productcolor_id));
            if (increaseMoney !== null && value.show_corrida !== true && product.discount === 0 && discount === undefined) {
                value.price = product.price + increase;
            } else {
                value.price = product.price;
            }
            if (value.plus) {
                value.price += value.plus
            }
            value.discount = product.discount;
            value.cost = product.cost;
            value.productname = product.name;
            value.colorname = product.colors.find(val => val.id === value.productcolor_id).name;
            value.product_id = product.id;
        });

        localStorage.setItem('Pre_Code_005', JSON.stringify({
            address: Address,
            address_id: Address.id,
            email: email,
            card: Card,
            //card:{id:state.Card.id},
            amount: amount2,
            service: Address.ispicking ? 0 : servicio,
            discount: discount,
            discount_corrida,
            sellonline: sellOnline ? sellonline : 0,
            items: SelectedItems,
            //pagoToken,
            account: account_id,
            secure: secure,
            inventoryU: inv_inf ? true : false,
            revision: revision,
            //order_status,
            discount_money: discountMoney,
            rewards: rewardsFlag,
            rewards_count: newReawards_count,
            name,
            comments: comments,
            platforms: 'Web',
            business_name: business[0].name,
            ip
        }));
    };
    const CompleteFunction = (order_status) => {
        //facebookPurchase();
        //setPrint(order_status);
        setIsVisible(false);

        SelectedItems.forEach(value => {
            let product = Product.find(val => val.colors.find(val => val.id === value.productcolor_id));
            if (increaseMoney !== null && value.show_corrida !== true && product.discount === 0 && discount === undefined) {
                value.price = product.price + increase;
            } else {
                value.price = product.price;
            }
            if (value.plus) {
                value.price += value.plus
            }
            value.discount = product.discount;
            value.cost = product.cost;
            value.productname = product.name;
            value.colorname = product.colors.find(val => val.id === value.productcolor_id).name;
            value.product_id = product.id;

        });
        addOrder({
            address: Address,
            address_id: Address.id,
            email: email,
            card: Card,
            //card:{id:state.Card.id},
            amount: amount2,
            service: Address.ispicking ? 0 : servicio,
            discount: discount,
            discount_corrida,
            sellonline: sellOnline ? sellonline : 0,
            items: SelectedItems,
            //pagoToken,
            account: account_id,
            secure: secure,
            inventoryU: inv_inf ? true : false,
            revision: revision,
            order_status,
            discount_money: discountMoney,
            rewards: rewardsFlag,
            rewards_count: newReawards_count,
            name,
            comments: comments,
            platforms: 'Web',
            business_name: business[0].name,
            ip

        }, nav, setShowActivity);
    };

    const submitOrder = () => {
        if (Card.id !== 1) {
            if (!showActivity) {
                if (rol === 'Admin' || rol === 'Support' || rol === 'Super') {
                    setIsVisible(!isVisible);
                } else {
                    CompleteFunction();
                }
            }
        } else {
            setShowWeb(true);
            setLocalStorage();
            getMercadoToken({
                amount: amount2,
                title: business[0].name
            }, setShowActivity);
        }
    };

    const GoogleAnaytics = (evento, cantidad, text) => {
        let arrayItems = [];
        SelectedItems.forEach(value => {
            let product = Product.find(val => val.colors.find(val => val.id === value.productcolor_id));
            arrayItems.push({
                item_id: value.id,
                item_name: product.name,
                coupon: '',
                discount: product.discount,
                affiliation: business[0].name,
                item_brand: business[0].name,
                item_category: '',
                item_variant: product.colors.find(val => val.id === value.productcolor_id).name,
                price: product.price,
                currency: 'MXN',
                quantity: value.items
            });

        });

        window.gtag('event', evento, {
            coupon: '',
            currency: 'MXN',
            items: arrayItems,
            shipping_tier: text,
            payment_type: text,
            value: cantidad
        });
    };


    return (
        <div className="App">
            <div className="navContainer">
                <div className="navBar">
                    <div className="headerNameCenter">{business[0].name}</div>

                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon" />
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12} />
                    <div className="nav_sub_title">Confirmación de pedido</div>
                </div>
            </div>
            {validation === true &&
                <div className="comfirmation_alert_box">
                    <div>
                        <div className="comfirmation_alert_title">Estimado cliente</div>
                    </div>
                    <div className="comfirmation_alert_text">Uno o más productos no están disponibles, su lista se ha actualizado,
                        favor de revisar antes de finalizar la compra.</div>
                </div>
            }
            {/*(showWeb && mercadoToken != null) &&
                <form id={FORM_ID} method="GET" />
            */}

            <div className="confirmation_list">
                <div className="confirmation_card_address">
                    <div className="confirmation_title">Dirección</div>
                    {ispicking &&
                        <div className="confirmation_subCard">
                            <div className="confirmation_row">
                                <FaStoreAlt className="confirmation_icon_big" />
                                <div className="confirmation_subTitle">Recoger en sucursal (sin cargo)</div>
                            </div>
                            <div className="confirmation_select">
                                <div className="confirmation_select_box1" onClick={() => { setShowSelect1(!showSelect1); }}>
                                    {Address.ispicking
                                        ? <BsCircleFill style={{ marginRight: 20 }} color={'#FF99FC'} size={16} />
                                        : <BsCircle style={{ marginRight: 20 }} color={'gray'} size={16} />
                                    }
                                    {Address.ispicking
                                        ? <div>
                                            <div className="confirmation_item_selected">{Address.name}</div>
                                            <div className="confirmation_item_selected">{Address.address}</div>
                                            <div className="confirmation_item_selected">{Address.city}, {Address.state1}</div>
                                        </div>
                                        : <div className="confirmation_placeholder">Seleccione una sucursal</div>
                                    }
                                    <AiOutlineDown style={{ marginLeft: 20 }} color='gray' />
                                </div>
                                {showSelect1 &&
                                    <div>
                                        {state.Offices.map((value, key) => {
                                            return (
                                                <div key={key} className="confirmation_select_box2"
                                                    onClick={() => {
                                                        setAddress({ ...value, ispicking, noService: false, pack: '' });
                                                        GoogleAnaytics('add_shipping_info', amount2, 'Recoger en sucursal');
                                                        setShowSelect1(false);
                                                    }}>
                                                    <div className="confirmation_item">{value.name}</div>
                                                    <div className="confirmation_item">{value.address}</div>
                                                    <div className="confirmation_item">{value.city}, {value.state1}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {isservice &&
                        <div className="confirmation_subCard">
                            <div className="confirmation_row">
                                <FaTruck className="confirmation_icon_big" />
                                <div className="confirmation_subTitle">Envío a domicilio</div>
                            </div>
                            {/* <div className="confirmation_subTitle">Cargo por envío: ${parseFloat(service).toFixed(2)} MXN</div> */}
                            <div className="confirmation_select">
                                {state.Address.length > 0 &&
                                    <div className="confirmation_select_box1" onClick={() => { setShowSelect2(!showSelect2); }}>
                                        {(!Address.ispicking && Address.id !== undefined)
                                            ? <BsCircleFill style={{ marginRight: 20 }} color={'#FF99FC'} size={16} />
                                            : <BsCircle style={{ marginRight: 20 }} color={'gray'} size={16} />
                                        }
                                        {(!Address.ispicking && Address.id !== undefined)
                                            ? <div>
                                                <div className="confirmation_item_selected">{Address.street} #{Address.num_ext}</div>
                                                <div className="confirmation_item_selected">{Address.suburb}</div>
                                                <div className="confirmation_item_selected">{Address.city}, {Address.state1}</div>
                                                <div className="confirmation_item_selected">{Address.cp}</div>
                                            </div>
                                            : <div className="confirmation_placeholder">Selecciona una dirección</div>
                                        }
                                        <AiOutlineDown style={{ marginLeft: 20 }} color='gray' />
                                    </div>
                                }
                                {showSelect2 &&
                                    <div>
                                        {state.Address.map((value, key) => {
                                            let findCity = codigos_postales.find(val => val.toUpperCase() === value.city.toUpperCase());
                                            value.serviceFree = findCity !== undefined ? true : false;
                                            return (
                                                <div key={key} className="confirmation_select_box2"
                                                    onClick={() => {
                                                        setAddress(value);
                                                        addCard({});
                                                        GoogleAnaytics('add_shipping_info', amount2, 'Envío a domicilio');
                                                        setShowSelect2(false);
                                                    }}>

                                                    {findCity
                                                        ? <div className="confirmation_subTitleCargo">Cargo por envío: ${parseFloat(0).toFixed(2)} MXN</div>
                                                        : <div className="confirmation_subTitleCargo">Cargo por envío: ${parseFloat(service).toFixed(2)} MXN</div>}
                                                    <div className="confirmation_item">{value.street} #{value.num_ext}</div>
                                                    <div className="confirmation_item">{value.suburb}</div>
                                                    <div className="confirmation_item">{value.city}, {value.state1}</div>
                                                    <div className="confirmation_item">{value.cp}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                <div className="conf_add_box">
                                    <BsPlusCircleFill size={14} color={'gray'} />
                                    <div className="conf_add_address"
                                        onClick={() => navigate("/address_create", { state: { account_id } })}>Agrega nueva dirección</div>
                                </div>
                                {state.Address.length > 0 &&
                                    <div className="confirmation_select">
                                        <div onClick={() => {
                                            //setNotService({noService:!noService.noService, pack:''});
                                            if (Address.ispicking) {
                                                setAddress({});
                                            } else {
                                                setAddress({ ...Address, noService: !Address.noService, pack: '' });
                                            }
                                        }}>
                                            <div className="conf_add_box">
                                                <div >
                                                    {Address.noService
                                                        ? <BsCheckSquare size={14} color='gray' />
                                                        : <BsSquare size={14} color='gray' />
                                                    }
                                                </div>
                                                <div className="conf_add_address">
                                                    {/*<MaterialCommunityIcons name="truck" size={24} color="#FF52FA" style={{paddingLeft:10}} />*/}
                                                    Otras paqueterías (cotizar)
                                                </div>
                                            </div>
                                        </div>
                                        {Address.noService &&
                                            <div>
                                                <input
                                                    type="text"
                                                    name="pack"
                                                    id="pack"
                                                    onChange={(event) => {
                                                        setAddress({ ...Address, pack: event.target.value });
                                                    }}
                                                    placeholder="Paquetería"
                                                    className="confirm_input"
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>


                <div className="confirmation_card_payment">
                    <div className="confirmation_title">Pago</div>
                    <div className="confirmation_subCard">
                        <div className="confirmation_row">
                            <FaCreditCard className="confirmation_icon_big" />
                            <div className="confirmation_subTitle">Forma de pago</div>
                        </div>
                        <div className="confirmation_select">
                            <div className="confirmation_select_box1" onClick={() => { setShowSelect3(!showSelect3); }}>
                                {Card.id !== undefined
                                    ? <BsCircleFill style={{ marginRight: 20 }} color={'#FF99FC'} size={16} />
                                    : <BsCircle style={{ marginRight: 20 }} color={'gray'} size={16} />
                                }
                                {Card.id !== undefined
                                    ? <div>
                                        <div className="confirmation_item_selected">{Card.name}</div>
                                    </div>
                                    : <div className="confirmation_placeholder">Seleccione una forma de pago</div>
                                }
                                <AiOutlineDown style={{ marginLeft: 20 }} color='gray' />
                            </div>
                            {showSelect3 &&
                                <div>
                                    {arrayMethodsMain.map((value, key) => {
                                        if (value !== '') {
                                            return (
                                                <div key={key} className="confirmation_select_box2"
                                                    onClick={() => {
                                                        addCard({ id: value.id, name: value.name });
                                                        GoogleAnaytics('add_payment_info', amount2, value.name);
                                                        setShowSelect3(false);
                                                    }}>
                                                    <div className="confirmation_item">{value.name}</div>
                                                </div>
                                            )
                                        } else {
                                            return <div key={key}></div>
                                        }
                                    })}
                                </div>
                            }
                        </div>
                    </div>

                    {Card.id === 3 &&
                        <div className="order_spei">
                            <h3 style={{ margin: 10 }}>Información</h3>
                            <div className="order_text_spei">{speiclave}</div>
                            <div className="order_text_spei_more">Después de realizar la transferencia, envía
                                tu comprobante de pago:</div>
                            <div className="order_text_spei_more">Whatsapp: {business[0].phone}</div>
                            <div className="order_text_spei_more">Email: zapateriasmay@gmail.com</div>
                        </div>
                    }

                    <div className="confirmation_title" style={{ paddingTop: 20 }}>Resumen del pedido</div>
                    <TotalBox
                        Product={Product}
                        item={SelectedItems}
                        discount2={discount}
                        discount_corrida={discount_corrida}
                        sellonline={sellOnline ? sellonline : 0}
                        service={state.Address.ispicking ? 0 : servicio}
                        showService={true}
                        secure={secure}
                        card={Card.id === 1 ? true : false}
                        discount_money={discountMoney}
                        increaseMoney={increaseMoney}
                    //fee={fee}
                    //notCre={notCre}
                    />
                    {isVisible &&
                        <div className="confirmation_isvisible">
                            <div className="confirmation_icon" onClick={() => CompleteFunction(false)}>
                                <MdWeb size={72} color={'#00C1FF'} />
                                <div className="confirmation_icon_text">En línea</div>
                            </div>
                            <div className="confirmation_icon" onClick={() => CompleteFunction(true)}>
                                <MdStoreMallDirectory size={72} color={'#FF52FA'} />
                                <div className="confirmation_icon_text">En tienda</div>
                            </div>
                        </div>
                    }

                    {showActivity && <div className="loader"></div>}
                    <div className="primary__button cart"
                        onClick={() => {
                            if (Address.id !== undefined && Card.id !== undefined) {
                                if (paqueteriaFlag === true) {
                                    if (showActivity === false) {
                                        if (inv_inf === true) {
                                            validateOrder({ items: SelectedItems }, submitOrder, setShowActivity);
                                        } else {
                                            submitOrder();
                                        }
                                    }
                                } else {
                                    setMessageSubmit('Escriba el nombre de la paquetería');
                                    setTimeout(() => setMessageSubmit(''), 2500);
                                }

                            } else {
                                let msj = '';
                                if (Address.id === undefined) {
                                    msj = 'Seleccione una dirección';
                                } else {
                                    msj = 'Seleccione una forma de pago';
                                }
                                setMessageSubmit(msj);
                                setTimeout(() => setMessageSubmit(''), 2500);
                            }
                        }}
                    >{textBtn}
                    </div>
                </div>
            </div>

            {messageSubmit !== '' &&
                <div className="toast__component">
                    <div className="add__cart--toast">
                        <div className="add__cart--text">{messageSubmit} </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default BasketConfirmationScreen;