import createDataContext from './createDataContext';
import Api from '../api/Api';
//import { navigate } from '../navigationRef';

const orderReducer = (state, action) => {
    switch (action.type) {
        case 'add_item':
            let selectDummy = state.SelectedItems;
            let findSelect = selectDummy.find(val => val.id_basket === action.payload.id_basket);
            if (findSelect === undefined) {
                selectDummy.push(action.payload);
            } else {
                if (action.payload.items > 0) {
                    selectDummy.map(val => val.id_basket === action.payload.id_basket
                        ? (val.items = action.payload.items, val.limitedMsg = action.payload.limitedMsg)
                        : val);
                } else {
                    //console.log(state.SelectedItems);
                    selectDummy = selectDummy.filter(val => val.id_basket !== action.payload.id_basket);
                }
            }
            return { ...state, SelectedItems: selectDummy, confirmOrder: false, addItemHold: false };
        case 'add_corrida':
            action.payload.map(value => {
                let findSelect = state.SelectedItems.find(val => val.id_basket === value.id_basket);

                if (findSelect === undefined) {
                    state.SelectedItems.push(value);
                } else {
                    if (value.items > 0) {
                        state.SelectedItems.map(val => val.id_basket === value.id_basket
                            ? (val.items = value.items, val.limitedMsg = value.limitedMsg)
                            : val);
                    } else {
                        state.SelectedItems = state.SelectedItems.filter(val => val.id_basket !== value.id_basket);
                    }
                }
            });
            return { ...state, SelectedItems: state.SelectedItems, confirmOrder: false, addItemHold: false };

        case 'add_item_hold':
            return { ...state, addItemHold: true };
        case 'set_services':
            return {
                ...state, discount: action.payload.discount,
                service: action.payload.service,
                amount: action.payload.amount,
                revision: action.payload.revision,
                comments: action.payload.comments,
                GoogleItems: null
            };
        case 'set_items':
            return { ...state, SelectedItems: action.payload, confirmOrder: false };
        case 'set_address':
            return { ...state, Address: action.payload };
        case 'set_email':
            return { ...state, email: action.payload.email };
        case 'add_card':
            return { ...state, Card: action.payload };
        case 'set_account':
            return { ...state, account_id: action.payload };
        case 'set_secure':
            return { ...state, secure: action.payload };
        case 'validate_order':
            action.payload.array.forEach(value => {
                state.SelectedItems.map(val => val.id === value.id
                    ? (val.lastItems = val.items, val.items = value.items) //val.validItems = value.items
                    : val);
            });
            return { ...state, Loading: false, validation: !action.payload.status };
        case 'add_order':
            let addNewOrder = state.Orders;
            addNewOrder.push(action.payload);

            return {
                ...state,
                Orders: addNewOrder,
                SelectedItems: [],
                Plus: [],
                Card: {},
                Address: {},
                errorMessage: '',
                confirmOrder: true,
                secure: false,
                email: '',
                revision: false,
                mercadoToken: null,
                validation: null,
                Loading: false,
                printOrder: action.payload.order_status,
                printId: action.payload.id,
                discountMoney: null,
                increaseMoney: null,
                rewards_count: action.payload.rewards_count,
                newOrderNoti: true,
                GoogleItems: action.payload
            };
        case 'delete_order':
            const deleteOrder = state.Orders.filter(val => val.id !== action.payload.id);
            return { ...state, Orders: deleteOrder, errorMessage: '' };
        case 'read_order':

            return { ...state, Orders: action.payload, errorMessage: '', Loading: false, newOrderNoti: false };
        case 'read_pending_order':
            return { ...state, PendingOrders: action.payload, errorMessage: '', Loading: false };
        case 'get_basket_all':
            return { ...state, basketAll: action.payload, errorMessage: '', Loading: false };
        case 'get_baskAndPend':
            return { ...state, basketById: action.payload.basket, PendingOrders: action.payload.pending, errorMessage: '' };
        case 'update_basket_all':
            if (action.payload.items === 0) {
                state.basketAll = state.basketAll.filter(val => val.id_basket !== action.payload.id_basket);
            } else {
                state.basketAll.map(val => val.id_basket === action.payload.id_basket
                    ? val.items = action.payload.items
                    : val);
            }
            return { ...state, basketAll: state.basketAll };
        case 'delete_item':
            const deleted = state.SelectedItems.filter(val => val.id_basket !== action.payload.id_basket);
            //const deleteAllbasket = state.basketAll.filter(val => val.id_basket !== action.payload.id_basket);
            return { ...state, SelectedItems: deleted, errorMessage: '' };
        case 'delete_AllItems':
            return { ...state, SelectedItems: [], errorMessage: '' };
        case 'delete_corrida':
            let deleteCorrida = state.SelectedItems;
            let deleteAll = state.basketAll;
            action.payload.selectArray.map(value => {
                deleteCorrida = deleteCorrida.filter(val => val.id_basket !== value.id_basket);
                deleteAll = deleteAll.filter(val => val.id_basket !== value.id_basket);
            })
            return { ...state, SelectedItems: deleteCorrida, basketAll: deleteAll, errorMessage: '' };
        case 'get_token':
            return { ...state, global_id: action.payload };

        case 'update_order':
            const updateOrderStatus = state.Orders.map(value => value.payment_id === action.payload.id
                ? value.status = action.payload.status
                : value);
            return { ...state, Orders: updateOrderStatus, errorMessage: '' };
        case 'update_payment':
            const updatePayment = state.Orders.map(value => value.payment_id === action.payload.id
                ? { ...value, payment_status: action.payload.status }
                : value);
            return { ...state, Orders: updatePayment, errorMessage: '', mercadoToken: null };
        case 'update_payment_method':
            const updatePaymentM = state.Orders.map(value => value.id === action.payload.id
                ? { ...value, pay_method: action.payload.method, transaction_details: action.payload.transaction }
                : value);
            return { ...state, Orders: updatePaymentM, errorMessage: '' };
        case 'do_pay':
            return { ...state, doPay: action.payload };
        case 'add_item_order':
            let findOrder = state.Orders.find(value => value.id === action.payload.orders_id);

            if (findOrder) {
                let findItem = findOrder.items.find(value => value.item_id === action.payload.item_id);
                if (findItem === undefined) {
                    findOrder.items.push(action.payload);
                } else {
                    if (action.payload.items >= 0) {
                        findOrder.items.map(val => val.item_id === action.payload.item_id
                            ? val.items = action.payload.items
                            : val)
                    }
                }
            }
            return { ...state, addItemHold: false };
        case 'delete_item_order':
            let findOrder2 = state.Orders.find(value => value.id === action.payload.orders_id);
            findOrder2.items = findOrder2.items.filter(val => val.item_id !== action.payload.item_id);
            return { ...state, errorMessage: '' };
        case 'set_basket':
            return { ...state, SelectedItems: action.payload, errorMessage: '' };
        case 'mercado_token':
            return { ...state, mercadoToken: action.payload };
        case 'loading':
            return { ...state, Loading: true };
        case 'get_year_report':
            return { ...state, YearReport: action.payload };
        case 'read_reports':
            return { ...state, Reports: action.payload, Loading: false };
        case 'read_reports_detail':
            return { ...state, reportDetail: action.payload, Loading: false };
        case 'read_reports_clients':
            return { ...state, customers: action.payload, Loading: false };
        case 'sell_online':
            return { ...state, sellOnline: action.payload };
        case 'update_package':
            const updatePack = state.Orders.map(value => value.id === action.payload.id
                ? { ...value, comments_pack: action.payload.input }
                : value);
            return { ...state, Orders: updatePack, errorMessage: '' };
        case 'update_comments':
            const updateComment = state.Orders.map(value => value.id === action.payload.id
                ? { ...value, comments: action.payload.input }
                : value);
            return { ...state, Orders: updateComment, errorMessage: '' };
        case 'set_order_discount':
            const updateDiscount = state.Orders.map(value => value.id === action.payload.id
                ? { ...value, discount_money: action.payload.input, discount_comment: action.payload.comments }
                : value);
            return { ...state, Orders: updateDiscount, errorMessage: '' };
        case 'set_discountMayoreo':
            state.Orders.map(value => value.id === action.payload.id
                ? value.discount = action.payload.input
                : value);
            return { ...state, errorMessage: '' };
        case 'setDiscountMoney':
            return { ...state, discountMoney: action.payload, errorMessage: '' };
        case 'setIncreaseMoney':
            return { ...state, increaseMoney: action.payload, errorMessage: '' };
        case 'setModifyPro':
            let updatePlus = state.Plus;
            if (updatePlus.find(val => val.id_basket === action.payload.id_basket)) {
                updatePlus.map(val => val.id_basket === action.payload.id_basket
                    ? val.plus = action.payload.plus
                    : val);
            } else {
                updatePlus.push(action.payload);
            }
            return { ...state, Plus: updatePlus, errorMessage: '' };
        case 'read_credit_note':
            //let updCre = state.account_id;
            //updCre.credit_note = action.payload.credit_note;
            return { ...state, /*account_id:updCre,*/ CreditNote: action.payload.credit_note };
        case 'getRewardsCount':
            let rew_count = action.payload.rewards_count;
            if (rew_count === null) {
                rew_count = 0;
            }
            return { ...state, rewards_count: rew_count };
        case 'error':
            return { ...state, errorMessage: action.payload, doPay: false };
        default:
            return state;

    }
};

const addItem2 = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'add_item_hold' });
            const response = await Api.post('/addItem2', req);
            dispatch({ type: 'add_item', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al guardar item \n(' + err.message + ').' });
        }
    };
};

const addItem5 = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'add_item_hold' });
            const response = await Api.post('/addItem5', req);
            dispatch({ type: 'add_item', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al guardar item \n(' + err.message + ').' });
        }
    };
};

const addItem6 = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'add_item_hold' });
            const response = await Api.post('/addItem6', req);
            dispatch({ type: 'add_item', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al guardar item \n(' + err.message + ').' });
        }
    };
};

const addCorrida = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'add_item_hold' });
            const response = await Api.post('/addCorrida', req);
            dispatch({ type: 'add_corrida', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al guardar item Corrida \n(' + err.message + ').' });
        }
    };
};

const getBasket = (dispatch) => {
    return async (req) => {
        try {
            const response = await Api.post('/getBasket', req);
            dispatch({ type: 'set_items', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar item \n(' + err.message + ').' });
        }
    };
};

const getBasketV2 = (dispatch) => {
    return async (req) => {
        try {
            const response = await Api.post('/getBasketV2', req);
            dispatch({ type: 'set_items', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar item \n(' + err.message + ').' });
        }
    };
};

const getBasketAll = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'loading' });
            const response = await Api.get('/getBasketAll');
            dispatch({ type: 'get_basket_all', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar item \n(' + err.message + ').' });
        }
    };
};

const getBaskAndPend = (dispatch) => {
    return async (req) => {
        try {
            const response = await Api.post('/getBaskAndPend', req);
            dispatch({ type: 'get_baskAndPend', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar item \n(' + err.message + ').' });
        }
    };
};


const updateItemBasket = (dispatch) => {
    return async (req) => {
        try {
            //const response = await Api.post('/addItem5', req);
            //dispatch({ type:'update_basket_all', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar item \n(' + err.message + ').' });
        }
    };
};

const cleanSelectd = (dispatch) => {
    return (req) => {
        dispatch({ type: 'set_items', payload: [] });
    };
};



const deleteItem = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/deleteItem', req);
            dispatch({ type: 'delete_item', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al borrar item \n(' + err.message + ').' });
        }
    };
};

const deleteAllItems = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/deleteAllItems', req);
            dispatch({ type: 'delete_AllItems' });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al borrar todo \n(' + err.message + ').' });
        }
    };
};

const deleteCorrida = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/deleteCorrida', req);
            dispatch({ type: 'delete_corrida', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al borrar corrida \n(' + err.message + ').' });
        }
    };
};


const setServices = (dispatch) => {
    return (req) => {
        dispatch({ type: 'set_services', payload: req });
    };
};

const setAddress = (dispatch) => {
    return (req) => {
        dispatch({ type: 'set_address', payload: req });
    };
};

const setAccount = (dispatch) => {
    return (req) => {
        dispatch({ type: 'set_account', payload: req });
    };
};

const setSecure = (dispatch) => {
    return (req) => {
        dispatch({ type: 'set_secure', payload: req });
    };
};

const setEmail = (dispatch) => {
    return async () => {
        try {
            const response = await Api.get('/readEmail');
            dispatch({ type: 'set_email', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar email \n(' + err.message + ').' });
        }
    };
};

const addCard = (dispatch) => {
    return (req) => {
        try {
            dispatch({ type: 'add_card', payload: req });
            //navigate('BasketConfirmation');
        } catch (err) {
        }
    };
};

const setSellOnline = (dispatch) => {
    return (req) => {
        dispatch({ type: 'sell_online', payload: req });
    };
};

const validateOrder = (dispatch) => {
    return async (req, submitOrder, show) => {
        try {
            show(true);
            dispatch({ type: 'loading' });
            const response = await Api.post('/validateOrder', req);
            dispatch({ type: 'validate_order', payload: response.data });
            if (response.data.status === true) {
                submitOrder();
            } else {
                show(false);
            }

        } catch (err) {
            show(false);
            //dispatch({type: 'error', payload:'* Error al crear pedido \n('+err.message+').'});
        }
    };
};

const addOrder = (dispatch) => {
    return async (req, nav, show) => {
        try {
            show(true);
            const response = await Api.post('/addOrder', req);
            req.id = response.data.id;
            dispatch({ type: 'add_order', payload: req });
            setTimeout(() => nav(), 500);
        } catch (err) {
            show(false);
            dispatch({ type: 'error', payload: '* Error al crear pedido \n(' + err.message + ').' });
        }
    };
};

const deleteOrder = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/deleteOrder', req);
            dispatch({ type: 'delete_order', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al borrar Pedido \n(' + err.message + ').' });
        }
    };
};

const addItemOder = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'add_item_hold' });
            const response = await Api.post('/addItemOder2', req);
            dispatch({ type: 'add_item_order', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar email \n(' + err.message + ').' });
        }
    };
};

const deleteItemOrder = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/deleteItemOrder', req);
            dispatch({ type: 'delete_item_order', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al borrar item \n(' + err.message + ').' });
        }
    };
};

const readOrders = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'loading' });
            const response = await Api.post('/readOrdersV1', req);
            dispatch({ type: 'read_order', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar pedidos \n(' + err.message + ').' });
        }
    };
};

const readOrdersUsers = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'loading' });
            const response = await Api.post('/readOrdersUsers');
            dispatch({ type: 'read_order', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar pedidos \n(' + err.message + ').' });
        }
    };
};

const readPendingOrders = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'loading' });
            const response = await Api.get('/readPendingOrders');
            dispatch({ type: 'read_pending_order', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar pedidos pendientes \n(' + err.message + ').' });
        }
    };
};

const updateOrder = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/updateOrder', req);
            dispatch({ type: 'update_order', payload: req });
            //navigate('Order');
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al actualizar pedido \n(' + err.message + ').' });
        }
    };
};
const updateOrder2 = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/updateOrder', req);
            dispatch({ type: 'update_order', payload: req });
            //navigate('Order');
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al actualizar pedido \n(' + err.message + ').' });
        }
    };
};

const updatePayment = (dispatch) => {
    return async (req) => {
        try {
            await Api.put('/updatePayment', req);
            dispatch({ type: 'update_payment', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al actualizar pago \n(' + err.message + ').' });
        }
    };
};

const updatePaymentMethod = (dispatch) => {
    return async (navigation, req) => {
        try {
            await Api.put('/updatePaymentMethod', req);
            dispatch({ type: 'update_payment_method', payload: req });
            navigation.goBack();
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al actualizar pago \n(' + err.message + ').' });
        }
    };
};

const doPayment = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'do_pay', payload: true });
            const response = await Api.put('/doPayment', req);
            dispatch({ type: 'update_payment', payload: response.data });
            //setTimeout(() =>  {dispatch({ type:'do_pay', payload: false }); navigate('Order')}, 2500);
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al actualizar pago \n(' + err.message + ').' });
        }
    };
};

const basketNotify = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/basketNotify', req);
            //dispatch({ type:'basket_notify', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al notificar basket \n(' + err.message + ').' });
        }
    };
};

const getMercadoToken = (dispatch) => {
    return async (req, show) => {
        try {
            show(true);
            const response = await Api.post('/getMercadoTokenWeb', req);
            dispatch({ type: 'mercado_token', payload: response.data });
            show(false);
        } catch (err) {
            show(false);
            dispatch({ type: 'error', payload: '* Error al pagar \n(' + err.message + ').' });
        }
    };
};

const getYearReport = (dispatch) => {
    return async (req) => {
        try {
            const response = await Api.post('/getYearReport', req);
            dispatch({ type: 'get_year_report', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al cargar reporte \n(' + err.message + ').' });
        }
    };
};

const updatePackage = (dispatch) => {
    return async (req) => {
        try {
            await Api.put('/updatePackage', req);
            dispatch({ type: 'update_package', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al actualizar paquetería \n(' + err.message + ').' });
        }
    };
};

const updateComments = (dispatch) => {
    return async (req) => {
        try {
            await Api.put('/updateComments', req);
            dispatch({ type: 'update_comments', payload: req });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al actualizar comentarios \n(' + err.message + ').' });
        }
    };
};

const setOrderDiscount = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/setOrderDiscount', req);
            dispatch({ type: 'set_order_discount', payload: req });
            req.navigation.goBack();
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al asignar descuento \n(' + err.message + ').' });
        }
    };
};

const setDiscountMoney = (dispatch) => {
    return (req) => {
        dispatch({ type: 'setDiscountMoney', payload: req.input });
        //req.navigation.goBack();
    };
};

const setDiscountMayoreo = (dispatch) => {
    return async (req) => {
        try {
            await Api.post('/setDiscountMayoreo', req);
            dispatch({ type: 'set_discountMayoreo', payload: req });
            req.navigation.goBack();
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al asignar descuento \n(' + err.message + ').' });
        }
    };
};

const setIncreaseMoney = (dispatch) => {
    return (req) => {
        dispatch({ type: 'setIncreaseMoney', payload: req.input });
        //req.navigation.goBack();
    };
};

const setModifyPro = (dispatch) => {
    return (req) => {
        dispatch({ type: 'setModifyPro', payload: req });
    };
};

const readReports = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'loading' });
            const response = await Api.post('/readReports', req);
            dispatch({ type: 'read_reports', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al obtener reportes \n(' + err.message + ').' });
        }
    };
};

const readReportsDetail = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'loading' });
            const response = await Api.post('/readReportsDetail', req);
            dispatch({ type: 'read_reports_detail', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al obtener reportes \n(' + err.message + ').' });
        }
    };
};

const readReportsClients = (dispatch) => {
    return async (req) => {
        try {
            dispatch({ type: 'loading' });
            const response = await Api.post('/readReportsClients', req);
            dispatch({ type: 'read_reports_clients', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al obtener reportes \n(' + err.message + ').' });
        }
    };
};

const readCreditNote = (dispatch) => {
    return async (req) => {
        try {
            const response = await Api.post('/readCreditNote', req);
            dispatch({ type: 'read_credit_note', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al obtener nota de crédito \n(' + err.message + ').' });
        }
    };
};

const getRewardsCount = (dispatch) => {
    return async (req) => {
        try {
            const response = await Api.post('/getRewardsCount', req);
            dispatch({ type: 'getRewardsCount', payload: response.data });
        } catch (err) {
            dispatch({ type: 'error', payload: '* Error al obtener rewards \n(' + err.message + ').' });
        }
    };
};

export const { Provider, Context } = createDataContext(
    orderReducer,
    {
        addItem2, addItem5, addItem6, deleteItem, setServices, setAddress, setEmail, addCard, addOrder, readOrders, updateOrder, updateOrder2,
        updatePayment, addItemOder, setAccount, getBasket, cleanSelectd, setSecure, getBasketAll, updateItemBasket,
        deleteItemOrder, deleteOrder, doPayment, getMercadoToken, basketNotify, getYearReport, addCorrida, deleteCorrida,
        setSellOnline, updatePackage, setOrderDiscount, setDiscountMoney, setIncreaseMoney, setModifyPro, readReports,
        readReportsDetail, readReportsClients, readPendingOrders, updatePaymentMethod, updateComments, readCreditNote,
        readOrdersUsers, getBaskAndPend, setDiscountMayoreo, deleteAllItems, getRewardsCount, validateOrder, getBasketV2
    },
    {
        SelectedItems: [], Orders: [], Card: {}, Address: {}, OrderFilter: [], filter: 'Pendiente', errorMessage: '',
        discount: 0, service: 0, amount: 0, global_id: '', email: '', account_id: null, basket: [], basketAll: [], secure: false,
        confirmOrder: false, addItemHold: false, revision: false, doPay: false, mercadoToken: null, Loading: false, YearReport: null,
        sellOnline: true, printId: null, discountMoney: null, increaseMoney: null, Plus: [], reportDetail: [], CreditNote: null,
        customers: [], basketById: [], PendingOrders: [], rewards_count: 0, validation: null, comments: null,
        GoogleItems: null, Reports: {
            price: 0,
            countOrders: 0,
            items: 0,
            services: 0,
            cost: 0,
            labelDaysMonth: [''],
            dataSetsMonth: [0],
            labelDaysDetail: []
        }, newOrderNoti: false
    }
);