import React, { useContext } from "react";
import { Context as AddressContext } from '../context/AddressContext';
import AddressForm from '../components/AddressForm';
import { useNavigate, useLocation } from "react-router-dom";

const AddressCreateScreen = () => {
    const {state, addAddress} = useContext(AddressContext);
    const navigate = useNavigate();
    const location = useLocation();
    const nav = () => {
        navigate(-1);
    };

    return(
        <AddressForm
            item={{}}
            onSubmit={addAddress}
            nav={nav}
            errorMessage={state.errorMessage}
            account_id={location.state !== undefined ? location.state : null}
        />
    );
};

export default AddressCreateScreen;