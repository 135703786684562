import React from "react";

const TotalBox = ({ Product, item, discount2, service, showService, secure, card, discount_corrida, sellonline,
    discount_money, fee, increaseMoney, notCre }) => {

    let subTotal = 0;
    let subTotal2 = 0;
    let discountTotal = 0;
    let discountCorridaTotal = 0;
    let totalItems = 0;
    let newTotalItems = 0;
    let secureAmount = 0;
    item.forEach(value => {
        let product = Product.find(val => val.colors.find(val => val.id === value.productcolor_id));
        if (product !== undefined) {
            //let color = product.colors.find(val => val.id ===  value.productcolor_id);
            let price = value.price || product.price;
            if (increaseMoney !== 0 && increaseMoney !== undefined && value.show_corrida !== true
                && product.discount === 0 && discount2 === undefined) {
                price += increaseMoney;
            }
            if (value.plus) {
                price += value.plus;
            }

            let discount = 0;
            if (value.discount >= 0) {
                discount = value.discount;
            } else {
                discount = product.discount;
            }
            let corrida = value.discount_corrida || discount_corrida;

            if (value.show_corrida === true || value.discount_corrida) {
                discountCorridaTotal += (corrida * value.items);
            } else {
                if (discount > 0) {
                    price -= discount;
                    price = Math.round((price) + ((price * sellonline) / 100));
                    price += discount;

                    discountTotal += (discount * value.items);
                } else if (discount2 !== undefined && discount2 !== null) {
                    discountTotal += (discount2 * value.items);
                } else {
                    price = Math.round((price) + ((price * sellonline) / 100));
                }
            }
            subTotal += (price * value.items);
            newTotalItems += value.items;
        }
    });
    discountTotal = Math.round(discountTotal) + discountCorridaTotal;
    let total = subTotal - discountTotal;

    if (discount_money > 0) {
        total = total - discount_money;
    }

    subTotal2 = total;
    total = total + service;
    if (secure) {
        secureAmount = parseFloat(total) * 0.03
        total = parseFloat(total) * 1.03
    }

    //let tc = (total * 0.0349) + 4 + (((total * 0.0349) + 4) * 0.16);
    /*
    if(card){
        total = total + tc;
    }
    */
    let te;
    if (fee) {
        te = (total * (fee / 100));
        total += te;
    }
    if (notCre > 0) {
        total -= notCre;
    }

    const moneyFormat = (value) => {
        let val = '$' + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return val;
    };

    return (
        <div className="totalBox">
            <div className="subTotal__wrapper">
                <div className="total__text">({newTotalItems} {newTotalItems > 1 ? ' Pares' : ' Par'})</div>
                <div className="total__value">{moneyFormat(subTotal)}</div>
            </div>
            {discount_money > 0 &&
                <div className="subTotal__wrapper">
                    <div className="total__text">Descuento adicional:</div>
                    <div className="total__value">{moneyFormat(discount_money)}</div>
                </div>
            }
            {discountTotal > 0 &&
                <div className="subTotal__wrapper">
                    <div className="total__text">Descuento:</div>
                    <div className="total__value">{moneyFormat(discountTotal)}</div>
                </div>
            }

            {showService &&
                <>
                    {subTotal2 != total &&
                        <div className="subTotal__wrapper subTotal_upperLine">
                            <div className="total__text">subTotal:</div>
                            <div className="total__value">{moneyFormat(subTotal2)}</div>
                        </div>
                    }

                    {service > 0 &&
                        <div className="subTotal__wrapper">
                            <div className="total__text">Costo de envío:</div>
                            <div className="total__value">{moneyFormat(service)}</div>
                        </div>
                    }

                    {/*card &&  
                        <div className="subTotal__wrapper">
                            <div className="total__text">TC:</div>
                            <div className="total__value">{moneyFormat(tc)}</div>
                        </div>
                    */}

                    {fee > 0 &&
                        <div className="subTotal__wrapper">
                            <div className="total__text">%</div>
                            <div className="total__value">{moneyFormat(te)}</div>
                        </div>
                    }

                    {notCre > 0 &&
                        <div className="subTotal__wrapper">
                            <div className="total__text">Nota de Crédito</div>
                            <div className="total__value">{moneyFormat(notCre)}</div>
                        </div>
                    }

                </>
            }



            <div className="total__wrapper total_upperLine">
                <div className="total__text">Total:</div>
                <div className="total_Final_text">{moneyFormat(total)} MXN</div>
                {/*state.SelectedItems.reduce((acc, item) => {
                    return acc + item.quantity * item.price;
                }, 0)*/}

            </div>

        </div>
    );
};

export default TotalBox;