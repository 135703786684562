import React, { useContext, useEffect } from 'react';
import { Context as AddressContext } from '../context/AddressContext';
import { Context as AuthContext } from '../context/AuthContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { useNavigate } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import { MdBusiness } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";

const OfficesScreen = () => {
    const {state, readOffices, deleteOffices} = useContext(AddressContext);
    const {state:{rol}} = useContext(AuthContext);
    const {state:{business}} = useContext(ConfigContext);

    const navigate = useNavigate();

    useEffect(() => {
        readOffices({business_id: business[0].id});
    },[]);
    
    return (
        <div className='App'>
            <div className="navContainer">
                <div className="navBar">
                {business.length > 0 && <div className="headerNameCenter">{business[0].name}</div>}
                {(rol !== 'User' && rol !== null && rol !== undefined) &&
                    <div className='nav_add_right' onClick={() => navigate('/office_create')}>AGREGAR</div>
                }
                </div>
            </div>
            <div className="nav_subNav">
                <div className="nav_row">
                    <FaHome className="nav_sub_icon"/>
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title" onClick={() => navigate('/account', { replace: true })}>Cuenta</div>
                </div>
                <div className="nav_row">
                    <AiOutlineRight className="nav_sub_icon" size={12}/>
                    <div className="nav_sub_title">Sucursales</div>
                </div>
            </div>
            <div className='offices_Title'>Sucursales</div>
            <div className='offices_List'>
                {state.Offices.map((item, key) => {

                
                        return (
                            <div key={key} className="offices_Card">
                                <div 
                                    onClick={()=> {
                                        if(rol !== 'User' && rol !== null && rol !== undefined){
                                            navigate("/office_modify",{state:{item}});
                                        }
                                    }}
                                >
                                    <MdBusiness className='offices_big_icon' />
                                    <div>
                                        <div className='offices_Title'>{item.name}</div>
                                        <div className='offices_Text'>Tel: {item.phone}</div>
                                        <div className='offices_Text'>{item.address}</div>
                                        <div className='offices_Text'>{item.city}, {item.state1}</div> 
                                    </div>                                  
                                </div>
                            </div>
                            );
                        })}
                           
                
            </div>     
            {state.Offices.length === 0 && <div className="style_full_height"/>}
        </div>
    );
};


export default OfficesScreen;