import React, { useContext, useEffect, useState } from "react";
import { Context as OrderContext } from '../context/OrderContext';
import { Context as AddressContext } from '../context/AddressContext';
import { Context as ConfigContext } from '../context/ConfigContext';
import { Context as ProductContext } from '../context/ProductContext';
import { Context as AuthContext } from '../context/AuthContext';
import ProductBoxH from '../components/ProductBoxH';
import TotalBox from '../components/TotalBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineRight, AiFillPrinter } from "react-icons/ai";
import { FaHome } from "react-icons/fa";
import ExportPDF from '../components/ExportPDF';

const OrderDetailScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;
    const rol = location.state.rol;
    const status = location.state.status;
    const { state: { Product, baseURL }, readProductOrders } = useContext(ProductContext);
    const { state, readAddressById } = useContext(AddressContext);
    const { state: { Orders, addItemHold }, updateOrder, updateOrder2,
        updatePayment, addItemOder, deleteItemOrder } = useContext(OrderContext);
    const { state: { speiclave, business } } = useContext(ConfigContext);
    const { state: { load } } = useContext(AuthContext);
    const [showActivity, setShowActivity] = useState(false);
    const [showActivity2, setShowActivity2] = useState(false);
    const [print, setPrint] = useState(false);
    const getItem = Orders.find(val => val.id === id);
    const [showPayMethod, setShowPayMethod] = useState(false);

    let render = false;
    useEffect(() => {
        window.scrollTo(0, 0);
        if (render === false) {
            readProductOrders({ id });
        }
        if (load === false && getItem === undefined) {
            navigate("/", { replace: true });
            //tryLocalSignin({ business_id: 5 });
            //readConfig(5);
        }
        render = true;
    }, []);


    if (getItem === undefined) {
        return <div></div>;
    }

    let addressLetter = 'envío';
    if (getItem.ispicking) {
        addressLetter = 'entrega';
    }
    const getOffice = state.Offices.find(val => val.id === getItem.address_id);
    let fee = 0;
    if (getItem.pay_method === 'Mercado pago' || getItem.pay_method === 'Paypal') {
        fee = 3;
    }
    let notCre = 0;
    if (getItem.transaction_details !== null && getItem.pay_method === 'Combinado') {
        if (getItem.transaction_details.method1 === 'Nota de crédito') {
            notCre = parseFloat(getItem.transaction_details.amount1);
        }
    }

    return (
        <>
            <div className="App no-print">
                <div className="navContainer">
                    <div className="navBar">
                        <div className="headerNameCenter">{status} - Folio {id}</div>
                    </div>
                </div>
                <div className="nav_subNav">
                    <div className="nav_row">
                        <FaHome className="nav_sub_icon" />
                        <AiOutlineRight className="nav_sub_icon" size={12} />
                        <div className="nav_sub_title" onClick={() => navigate('/', { replace: true })}>Home</div>
                    </div>
                    <div className="nav_row">
                        <AiOutlineRight className="nav_sub_icon" size={12} />
                        <div className="nav_sub_title" onClick={() => navigate('/orders', { replace: true })}>Pedidos</div>
                    </div>
                    <div className="nav_row">
                        <AiOutlineRight className="nav_sub_icon" size={12} />
                        <div className="nav_sub_title">Detalle de pedido</div>
                    </div>
                </div>
                <div className="order_list">

                    <div className="order_filter">
                        {rol !== 'User' &&
                            <div className="order_top_box_btn">
                                <AiFillPrinter className="order_icon" onClick={() => {
                                    setTimeout(() => setPrint(false), 5000);
                                    setPrint(true);
                                    //setTimeout(() => window.print(), 3000);
                                }} />
                            </div>
                        }

                        <div className="order_filter_box order_bottonWidth">
                            {getItem.ispicking
                                ? <div >
                                    <div className="order_box_text order_box_bold">Dirección de {addressLetter}</div>
                                    {getOffice !== undefined &&
                                        <div>
                                            <div className="order_box_text">{getOffice.name} </div>
                                            <div className="order_box_text">{getOffice.address}</div>
                                            <div className="order_box_text">{getOffice.city}, {getOffice.state1}</div>
                                        </div>
                                    }

                                </div>
                                : state.OrderAddress.id !== getItem.address_id
                                    ? <div className="order_cursor" onClick={() => {
                                        setShowActivity(true);
                                        readAddressById({ id: getItem.address_id });
                                    }}>
                                        <div className="order_box_row">
                                            <div className="order_box_text">Ver dirección de {addressLetter}</div>
                                            <AiOutlineRight size={14} style={{ paddingLeft: 10 }} />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="order_box_text">Dirección de {addressLetter}</div>
                                        <div className="order_box_text">{state.OrderAddress.street} #{state.OrderAddress.num_ext}</div>
                                        <div className="order_box_text">{state.OrderAddress.suburb}</div>
                                        <div className="order_box_text">{state.OrderAddress.city}, {state.OrderAddress.state1}</div>
                                        <div className="order_box_text">{state.OrderAddress.cp}</div>
                                        <div className="order_box_text">{state.OrderAddress.reference}</div>

                                    </div>
                            }
                        </div>

                        <div className="order_filter_box order_bottonWidth">
                            <div className="order_box_text order_box_bold">Información de pago</div>
                            <div className="order_box_row order_box_space">
                                <div>
                                    <div className="order_box_row">
                                        <div className="order_box_text">Pago con {getItem.pay_method}</div>
                                        {getItem.pay_method === 'SPEI' &&
                                            <div className="order_box_text_right" onClick={() => {
                                                setShowPayMethod(!showPayMethod)
                                            }}>{showPayMethod
                                                ? '(Ocultar Métodos)'
                                                : '(Métodos de pago)'
                                                }</div>
                                        }
                                    </div>
                                    <div className={getItem.payment_status === 'Realizado' ? "order_box_text order_green" : "order_box_text order_red"}>
                                        {getItem.payment_status}</div>
                                    {(getItem.transaction_details !== null && getItem.pay_method === 'Combinado') &&
                                        <div>
                                            <div className="order_box_text">1.- {getItem.transaction_details.method1} ${getItem.transaction_details.amount1}</div>
                                            <div className="order_box_text">2.- {getItem.transaction_details.method2} ${getItem.transaction_details.amount2}</div>
                                        </div>
                                    }
                                </div>
                                {(rol !== 'User' && getItem.payment_status !== 'Realizado' && getItem.status !== 'Cancelado')
                                    ?
                                    <div>
                                        <div className="order_cursor" onClick={() => {
                                            setShowActivity2(true);
                                            updatePayment({ id: getItem.payment_id, status: 'Realizado', orders_id: getItem.id });
                                            setTimeout(() => setShowActivity2(false), 1500);
                                        }}>
                                            <div className="order_box_text">Confirmar</div>
                                        </div>
                                    </div>
                                    : rol !== 'User' && getItem.payment_status === 'Realizado' && getItem.status === 'Pendiente'
                                        ? <div>
                                            <div className="order_cursor" onClick={() => {
                                                setShowActivity2(true);
                                                updatePayment({ id: getItem.payment_id, status: 'Pendiente' });
                                                setTimeout(() => setShowActivity2(false), 1500);
                                            }}>
                                                <div className="order_box_text">Cancelar</div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {(getItem.status === 'Pendiente' && getItem.payment_status === 'Pendiente') &&
                                    <div className="order_box_flex1">
                                        {/*
                                <div className="order_cursor" onClick={()=>{   
                                       navigate("/order_purchase",{state:{id}})
                                    }}>
                                    <div className="order_box_text">Cambiar</div>
                                </div> 
                                */}
                                    </div>
                                }

                            </div>
                        </div>

                        {((getItem.comments_pack !== null && getItem.comments_pack !== '') ||
                            (getItem.pack !== null && getItem.pack !== '')) &&
                            <div className="order_filter_box order_bottonWidth">
                                <div className="order_box_text order_box_bold">Paquetería</div>
                                <div className="order_box_text">Paquetería {getItem.pack}</div>
                            </div>
                        }
                        {(getItem.comments !== null && getItem.comments !== '') &&
                            <div className="order_filter_box order_bottonWidth">
                                <div className="order_box_text order_box_bold">Comentarios</div>
                                <div className="order_box_text">{getItem.comments}</div>
                            </div>
                        }
                        <div className="order_filter_box order_bottonWidth">
                            <div className="order_box_text order_box_bold">Resumen</div>
                            <TotalBox
                                Product={Product}
                                item={getItem.items}
                                discount2={getItem.discount}
                                service={getItem.services}
                                sellonline={getItem.sellonline}
                                showService={true}
                                secure={getItem.secure}
                                card={getItem.pay_method === 'Tarjeta' ? true : false}
                                discount_money={getItem.discount_money}
                                fee={fee}
                                notCre={notCre}
                            />
                        </div>


                        {rol !== 'User' && (getItem.status === 'Pendiente' || getItem.status === 'Pendiente de pago')
                            ?
                            <div className="order_box_row order_box_space">
                                <div className="order_button order_bg_blue" onClick={() => {
                                    setShowActivity2(true);
                                    getItem.status = 'Enviado';
                                    updateOrder(getItem);
                                    setTimeout(() => setShowActivity2(false), 800);
                                }}>
                                    <div className="order_button_text">Confirmar {addressLetter}</div>
                                </div>
                                <div className="order_button order_bg_red" onClick={() => {
                                    setShowActivity2(true);
                                    getItem.status = 'Cancelado';
                                    getItem.inventory = false;
                                    updateOrder2(getItem);
                                    setTimeout(() => setShowActivity2(false), 800);
                                }}>
                                    <div className="order_button_text">Cancelar</div>
                                </div>
                            </div>
                            : rol !== 'User' && getItem.status === 'Cancelado'
                                ? <div className="order_box_row order_box_space">
                                    <div className="order_button order_bg_orange" onClick={() => {
                                        setShowActivity2(true);
                                        getItem.status = 'Pendiente';
                                        getItem.inventory = false;
                                        updateOrder2(getItem);
                                        setTimeout(() => setShowActivity2(false), 800);
                                    }}>
                                        <div className="order_button_text">Abrir orden</div>
                                    </div>
                                </div>
                                : rol !== 'User' && getItem.status === 'Enviado' &&
                                <div className="order_box_row order_box_space">
                                    <div className="order_button order_bg_green" onClick={() => {
                                        setShowActivity2(true);
                                        getItem.status = 'Pendiente';
                                        getItem.inventory = true;
                                        updateOrder2(getItem);
                                        setTimeout(() => setShowActivity2(false), 800);
                                    }}>
                                        <div className="order_button_text">Abrir orden</div>
                                    </div>
                                </div>
                        }
                    </div>

                    {showPayMethod &&
                        <div className="order_spei">
                            <h3 style={{ margin: 10 }}>Información</h3>
                            <div className="order_text_spei">{speiclave}</div>
                            <div className="order_text_spei_more">Después de realizar la transferencia, envía
                                tu comprobante de pago:</div>
                            <div className="order_text_spei_more">Whatsapp: {business[0].phone}</div>
                            <div className="order_text_spei_more">Email: zapateriasmay@gmail.com</div>
                        </div>
                    }

                    <div className="order_card">
                        {getItem.items.map((item, key) => {
                            if (showActivity2 === false)
                                return (
                                    <div key={key}>
                                        <ProductBoxH
                                            Product={Product.find(value => value.colors
                                                .find(val => val.id === item.productcolor_id))}
                                            item={item}
                                            addItem={addItemOder}
                                            addItemHold={addItemHold}
                                            URL={baseURL}
                                            editable={rol !== 'User'
                                                ? getItem.status === 'Pendiente' || getItem.status === 'En revisión' || getItem.status === 'Pendiente de pago'
                                                    ? true
                                                    : false
                                                : false}
                                            //editable2={true}
                                            deleteItem={deleteItemOrder}
                                            discount2={getItem.discount}
                                            sellonline={getItem.sellonline}
                                            orderStatus={getItem.status}
                                        />
                                    </div>
                                );
                        })}
                    </div>
                </div>

            </div>
            {print === true &&
                <div className="App">
                    <ExportPDF id={id} auto={print} />
                </div>

            }
        </>
    );
};

export default OrderDetailScreen;